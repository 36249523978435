export const API_URL = {
  //auth...
  login: "/auth/login",
  get_profile: "/auth/get_profile",
  update_profile: "/auth/update_profile",
  forgotten_password: "/auth/forget_password",
  reset_password: "/auth/reset_password",

  userImage: "/user/upload_image",

  //mandate
  create_mandate: "/company/create_mandate",
  update_mandate: "/company/create_mandate/update/",
  initiate_mandate: "/company/create_mandate",
  get_mandate: "/company/view_mandate",
  approve_mandate: "/company/approve_mandate/",

  //company
  debit_account: "/company/debit_account",

  get_schedules: "/company/view_schedules/",
  confirmPayment: "/company/confirm_payment",
  register_company: "/company/add_company",
  get_customers: "/company/view_customers/",
  get_user_with_id: "/company/view_customers/by_id/",
  get_timeline: "/company/all_timelines/",
  get_timeline_by_id: "/company/timelines/",
  cancelSchedule: "/company/cancel_schedule/",
  account_balance: "/company/debit_account/account_balance/",
  update_schedule: "/company/create_mandate/update_schedule/",
  check_balance: "/company/debit_account/account_balance/",

  view_schedule: "/company/view_schedules/schedule/",
  requireMandate: "/company/create_mandate/require_mandate",
  get_transaction: "/company/view_schedules/transactions/histories",
  get_statistic: "/company/view_mandate/statistics",
  get_admin_mandates: "/admin/view_mandate",
  get_companies: "/admin/companies",
  admin_statistics: "/admin/statistics",
  admin_timeline: "/admin/timelines",

  due_loans: "/company/view_mandate/due_loan",

  //settings
  add_staff: "/company/edit_settings/add_staff",
  get_staff: "/company/edit_settings/get_staff",

  get_positions: "/company/edit_settings/positions",

  update_permission: "/company/edit_settings/permission",
  bank_lists: "/company/edit_settings/bank_lists",
  edit_staff: "/company/edit_settings/edit_staff/",
  edit_company: "/company/edit_settings/update_company",
  change_password: "/auth/change_password",

  //business apis
  get_company_staff: "/admin/staffs/",
  get_company_users: "/admin/users/",
  add_company: "/auth/add_company",
  create_plan: "/admin/create_plan",
  get_plans: "/admin/get_plan",
  update_plan: "/admin/update_plan/",
  create_subscription: "/admin/create_subscription",

  top_up_wallet: "/admin/top_up/wallet",

  //loan_settings
  create_loan_setting: "/company/edit_settings/create_loan_setting",
  get_loan_request: "/company/create_mandate/get_loan_request",
  verify_data: "/company/create_mandate/verify_data",
  approve_decline: "/company/create_mandate/decline_or_approve_kyc/",
  account_statement: "/company/create_mandate/get_account_statement/",
  check_credit_bureau: "/company/create_mandate/get_history/",
  update_kyc: "/company/create_mandate/update_kyc/",
  admin_get_loan_setting: "/company/edit_settings/get_loan_setting",
  verify_address: "/company/create_mandate/verify_address",
  verify_bvn: "/company/create_mandate/verify_data",
  verify_nin: "/company/create_mandate/verify_nin",

  //user apis
  get_user_mandate: "/user/get_mandate/",
  authorize_mandate: "/user/authorize_mandate/",
  verify_bvn_authorization: "/user/verify_bvn_authorization",
  verify_mandate: "/user/verify_mandate/",
  get_account_mandate: "/user/get_mandate_details/",
  authorize_reschedule: "/user/authorize_schedules_update/",

  get_loan_setting: "/user/get_loan_setting/",
  initiate_loan: "/user/initiate_loan/",
  loan_details: "/user/get_loan_request/",
  loan_kyc: "/user/loan_kyc/",
  save_account: "/user/save_account/",
  update_loan: "/user/update_loan/",
};
