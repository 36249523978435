import { useForm } from "react-hook-form";
import { Input } from "../../../common/form_inputs/Input";
import CustomButton from "../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useSaveAccount } from "../../../../api/mutation";

const GuarantorInfo = () => {
  const {
    updateCurrentPage,
    data: { userDetail },
  } = useCustomerLoanApplication();

  const { mutate, isPending } = useSaveAccount(userDetail?.id);

  const { showMessage, contextHolder } = useCustomMessage();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      ...userDetail?.guarantor_info,
    }
  });

  const onSubmit = (data) => {
    //submit the guarantor data to the server
    const payload = {
      guarantor_info: {
        first_name: data?.first_name,
        last_name: data?.last_name,
        phone: data?.phone,
        email: data?.email,
      }
    };

    mutate(payload, {
      onError: (error) => {
        const errMsg = error?.response?.data?.message || error?.message;

        showMessage("error", errMsg);

      },
      onSuccess: (data) => {
        updateCurrentPage("APPLICATION_COMPLETED", { response: { title: "Loan application submitted", desc: "Your application will be reviewed", continue: false } })
      },
    });
  };

  const onChangeValue = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName);
  };

  return (
    <>
      <main className="w-full max-w-lg md:mx-0 mx-auto flex flex-col gap-y-7 my-5">
        <h3 className="font-[500] text-[16px] text-center">Guarantor</h3>
        <div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Guarantor’s First Name
              </label>
              <Input
                name="first_name"
                defaultValue={getValues("first_name")}
                placeholder="Enter First Name"
                size={"large"}
                className={`text-[14px] ${errors.first_name ? "error" : ""}`}
                status={errors.first_name ? "error" : ""}
                {...register("first_name", {
                  required: "This field is required",
                })}
                onChange={(e) => onChangeValue(e, "first_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.first_name && errors.first_name.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="last_name" className="font-[400] text-[14px]">
                Guarantor's Last Name
              </label>
              <Input
                name="last_name"
                defaultValue={getValues("last_name")}
                placeholder="Enter Last Name"
                size={"large"}
                className={`text-[14px] ${errors.last_name ? "error" : ""}`}
                status={errors.last_name ? "error" : ""}
                {...register("last_name", {
                  required: "Last name is required",
                })}
                onChange={(e) => onChangeValue(e, "last_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.last_name && errors.last_name.message}
              </small>
            </div>
            {/* <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Relationship
              </label>
              <Select
                defaultValue={getValues("relationship")}
                placeholder="Select relationship"
                className="w-full"
                optionFilterProp="label"
                status={errors.relationship ? "error" : ""}
                {...register("relationship", {
                  required: "relationship is required",
                })}
                onChange={(value) => {
                  setValue("relationship", value);
                  trigger("relationship");
                }}
                options={[
                  { value: "Sister", label: "Sister" },
                  { value: "Brother", label: "Brother" },
                ]}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.relationship && errors.relationship.message}
              </small>
            </div> */}
            <div className="mb-2">
              <label htmlFor="phone" className="font-[400] text-[14px]">
                Phone Number
              </label>
              <Input
                name="phone"
                defaultValue={getValues("phone")}
                type="number"
                placeholder="Enter Your Phone number"
                size={"large"}
                className={`text-[14px] ${errors.phone ? "error" : ""}`}
                status={errors.phone ? "error" : ""}
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^0\d{10}$/,
                    message:
                      "Phone number must start with 0 and be 11 digits long",
                  },
                })}
                onChange={(e) => onChangeValue(e, "phone")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.phone && errors.phone.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="email" className="font-[400] text-[14px]">
                E-mail
              </label>
              <Input
                name="email"
                defaultValue={getValues("email")}
                type="email"
                placeholder="Enter Guarantor's Email"
                size={"large"}
                className={`text-[14px] ${errors.email ? "error" : ""}`}
                status={errors.email ? "error" : ""}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                onChange={(e) => onChangeValue(e, "email")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.email && errors.email.message}
              </small>
            </div>

            <div className="mt-7 mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                loading={isPending}
              >
                Send
              </CustomButton>
            </div>
          </form>
        </div>
      </main>

      {contextHolder}
    </>
  );
};

export default GuarantorInfo;
