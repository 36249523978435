import React, { useEffect, useState } from "react";
import { Input } from "../../../common/form_inputs/Input";
import { Select } from "../../../common/form_inputs/Select";
import { useForm } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useBankList } from "../../../../hooks/useBankList";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import { useSaveAccount } from "../../../../api/mutation";

const DisbursementAccountForm = () => {
  //external hooks

  //   const mutation = useUpdateCompany();

  const {
    updateCurrentPage,
    data: { userDetail },
  } = useCustomerLoanApplication();

  const { mutate, isPending } = useSaveAccount(userDetail?.id);

  const { data, isLoading: bankListLoading } = useBankList();

  const { showMessage, contextHolder } = useCustomMessage();

  const bank_list = data?.map((bank) => {
    return {
      ...bank,
      value: bank?.name,
      label: bank?.name,
    };
  });

  

  //

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      ...userDetail?.account_info,
    },
  });

  const onsubmit = (data) => {
    const payload = {
      account_info: {
        account_name: data?.account_name,
        account_number: data?.account_number,
        bank_name: data?.bank_name,
      },
    };

    mutate(payload, {
      onError: (error) => {
        const errMsg = error?.response?.data?.message || error?.message;

        showMessage("error", errMsg);
        
      },
      onSuccess: (data) => {

        if (userDetail?.loanSetting?.guarantor) {
          updateCurrentPage("GUARANTOR_DETAIL", {});
        } else {
          updateCurrentPage("APPLICATION_COMPLETED", {
            response: {
              title: "Loan application submitted",
              desc: "Your application will be reviewed",
              continue: false,
            },
          });
        }
      },
    });
  };

  const handleChange = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName);
  };

  const onChange = (value) => {
    const selected = bank_list.find((bank) => bank?.value === value);

    setValue("bank_name", selected?.name);

    trigger("bank_name");
  };

  return (
    <>
      <main className="w-full max-w-lg mx-auto px-4">
        <p className="my-5 font-[500] text-[16px] text-black capitalize">
          Provide Bank Account Where Loan Should Be Disbured
        </p>
        <form action="" onSubmit={handleSubmit(onsubmit)}>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px]">
              Account Name
            </label>
            <Input
              name="account_name"
              defaultValue={getValues("account_name")}
              placeholder="Enter Account Name"
              size={"large"}
              className={` text-[14px] ${errors.account_name ? "error" : ""}`}
              status={errors.account_name ? "error" : ""}
              {...register("account_name", {
                required: "Account Name is required",
              })}
              onChange={(e) => handleChange(e.target.value, "account_name")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.account_name && errors.account_name.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px]">
              Account Number
            </label>
            <Input
              name="account_number"
              defaultValue={getValues("account_number")}
              type="number"
              placeholder="Enter Account Number"
              size={"large"}
              className={` text-[14px] ${errors.account_number ? "error" : ""}`}
              status={errors.account_number ? "error" : ""}
              {...register("account_number", {
                required: "Account Number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Account Number must be 10 digits long",
                },
              })}
              onChange={(e) => handleChange(e.target.value, "account_number")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.account_number && errors.account_number.message}
            </small>
          </div>

          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px]">
              Bank
            </label>
            <Select
              defaultValue={getValues("bank_name")}
              showSearch
              placeholder="Select Bank"
              className="w-full"
              optionFilterProp="label"
              loading={bankListLoading}
              status={errors.bank_name ? "error" : ""}
              {...register("bank_name", {
                required: "Bank Name is required",
              })}
              onChange={onChange}
              options={bank_list}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.bank_name && errors.bank_name.message}
            </small>
          </div>

          <div className="mt-10 mb-3">
            <CustomButton
              type={"submit"}
              className={"w-full"}
              loading={isPending}
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </main>

      {contextHolder}
    </>
  );
};

export default DisbursementAccountForm;
