import React, { useMemo, useState } from "react";
import RenderSection from "./RenderSection";
import { Image } from "antd";
import avatar from "../../../../../../assets/avatar.png";
import CustomButton from "../../../../../common/CustomButton";
import OutlineCustomButton from "../../../../../common/OutlineCustomButton";
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import { useApproveOrDecline } from "../../../../../../api/admin_mutation";
import useCustomMessage from "../../../../../../hooks/zustand_hooks/useCustomMessage";
import ModalBox from "../../../../../common/ModalBox";
import { TextArea } from "../../../../../common/form_inputs/TextArea";
import { useGetLoanDetails } from "../../../../../../api/getHooks";
import StarLoader from "../../../../../loader/StarLoader";
import { useQueryClient } from "@tanstack/react-query";

const personal_information_fields = [
  { value: "name", label: "Name" },
  { value: "phone", label: "Phone" },
  { value: "email", label: "Email" },
  { value: "maritalStatus", label: "Marital Status" },
  { value: "dob", label: "Date of Birth" },
];
const guarantor_info_fields = [
  { value: "name", label: "Name" },
  { value: "phone", label: "Phone" },
  { value: "email", label: "Email" },
];
const address_information_fields = [
  { value: "address", label: "Home Address" },
  { value: "city", label: "City" },
  { value: "state", label: "State" },
  { value: "meter_number", label: "Meter Number", requireVerify: true,
    action_name: "Check",
    keyName: "meterNumber", },
];
const work_fields = [
  { value: "occupation", label: "Occupation" },
  { value: "workEmail", label: "Work Email" },
  { value: "salary", label: "Salary" },
  { value: "employer", label: "Employer" },
  { value: "employeraddress", label: "Employer's Address" },
];

const identity_fields = [
  {
    value: "bvn",
    label: "BVN",
    requireVerify: true,
    action_name: "Check Authenticy",
    keyName: "bvnIdentity",
  },
  {
    value: "nin",
    label: "NIN",
    requireVerify: true,
    action_name: "Check Authenticy",
    keyName: "ninIdentity"
  },
];

const credit_history_fields = [
  { value: "check_credit_bureau", label: "Check Credit bureau", link: true },
];
const bank_statement_fields = [
  { value: "bank_statment", label: "Request Bank Statement", link: true },
];

const KycProcess = () => {
  

  const [openComment, setOpenComment] = useState(false);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");

  const { showMessage, contextHolder } = useCustomMessage();

  const queryClient = useQueryClient()

  const {
    openDrawer,
    data: { user_data },
  } = useDrawer();

  const { data: getLoanDetail, isLoading: detailLoading } = useGetLoanDetails(
    user_data?.loan_id
  );

  const loan_details = getLoanDetail?.data?.data;

  const { mutate, isPending } = useApproveOrDecline(loan_details?.id);


  const handleClick = (status) => {
    setOpenComment(true);
    setStatus(status);
  };


  //========================= Action to approve or decline =========================
  const handleApproveOrDecline = () => {
    const payload = {
      comment,
      status,
    };

    mutate(payload, {
      onError: (error) => {
        const errMsg = error?.response?.data?.message || error?.message;

        showMessage("error", errMsg);
      },
      onSuccess: (data) => {
        const response = data?.data?.data;
        queryClient.invalidateQueries("loan_details");

        if(status==="approved"){
          openDrawer("NEW_MANDATE_OPTION", {}, "KYC_PROCESS");
        }

        showMessage(
          response?.status === "failed" ? "error" : "success", data?.data?.message
          // response?.message
        );

        setOpenComment(false);
      },
      onSettle: () => {
        setComment("");
      },
    });
  };

  //================================================================


  const allIsPending = useMemo(() => {
    if (!loan_details?.account_status) return false;
  
    const statusFields = [
      'bankStatement',
      'bioData',
      'bvnIdentity',
      'contact',
      'location',
      'ninIdentity',
      'profilePicture'
    ];
  
    return statusFields.every(field => 
      loan_details.account_status[field] === "pending"
    );
  }, [loan_details]);



  return (
    <>
      <main>
        <h4 className="font-[600] text-[18px] mb-5 text-center">Summary</h4>

{
  detailLoading? (
    <div className="flex justify-center items-center h-32">
      <StarLoader />
    </div>
  ): (
    <>
        <div className="flex justify-center mb-8">
          <Image
            preview={false}
            className="rounded-lg border border-[#908A8A]"
            width={200}
            src={loan_details?.profile_picture || avatar}
          />
        </div>

        <div className="flex flex-col gap-y-4">
          <RenderSection
            title={"Personal Information"}
            field_names={personal_information_fields}
            data={{
              ...loan_details?.bioData,
              name: loan_details?.first_name + " " + loan_details?.last_name,
              id: loan_details?.id,
              email: loan_details?.email,
              phone: loan_details?.phone,
              account_status: loan_details?.account_status,
            }}
            keyName={"bioData"}
            require_verify={true}
          />
          <RenderSection
            title={"Address Information"}
            field_names={address_information_fields}
            data={{
              ...loan_details?.contact,
              account_status: loan_details?.account_status,
              id: loan_details?.id,
            }}
            require_verify={true}
            keyName="contact"
            
          />
          <RenderSection
            title={"Work Information"}
            field_names={work_fields}
            data={{
              ...loan_details?.employmentDetails,
              id: loan_details?.id,
              account_status: loan_details?.account_status,
            }}
            require_verify={true}
            keyName="employmentDetails"
          />
          <RenderSection
            title={"Identity"}
            field_names={identity_fields}
            require_verify={false}
            data={{
              ...loan_details,
              nin: loan_details?.nin,
              bvn: loan_details?.bioData?.bvn,
              id: loan_details?.id,
              dob: loan_details?.bioData?.dob,
              account_status: loan_details?.account_status,
            }}
            checkAuthenticity={true}
            keyName={"identity"}
          />
          <RenderSection
            title={"Credit History"}
            field_names={credit_history_fields}
            coming_soon={false}
            data={{
              account_status: loan_details?.account_status,
              bvn: loan_details?.bioData?.bvn,
              id: loan_details?.id,
            }}
            keyName="credit_history"
          />
          {
            loan_details?.loanSetting?.account_statment && (
              <RenderSection
                title={"Bank Statement"}
                field_names={bank_statement_fields}
                coming_soon={false}
                data={{
                  account_status: loan_details?.account_status,
                  id: loan_details?.id,
                }}
                keyName="bankStatement"
              />
            )
          }
          {
            loan_details?.loanSetting?.guarantor && (
              <RenderSection
                title={"Guarantor Information"}
                field_names={guarantor_info_fields}
                data={{
                  ...loan_details?.guarantor_info,
                  name: loan_details?.guarantor_info?.first_name + " " + loan_details?.guarantor_info?.last_name,
                  id: loan_details?.id,
                }}
                keyName={"guarantor_info"}
                // require_verify={true}
              />
            )
          }
        </div>

        <div className="flex justify-center gap-x-8 mt-10">
     
          <OutlineCustomButton
            className={`w-full md:w-2/5`}
            onClick={() => handleClick(allIsPending? "rejected": "declined")}
            disabled={loan_details?.status==="rejected" || loan_details?.status==="declined"}
          >
            {
              loan_details?.status==="declined"? "Declined": "Decline"
            }
          </OutlineCustomButton>
            <CustomButton
              className={`w-full md:w-2/5`}
              onClick={() => loan_details?.status==="approved"? openDrawer("NEW_MANDATE_OPTION", {}, "KYC_PROCESS") : handleClick("approved")}
            >
              {
                loan_details?.status==="approved"? "Continue": "Approve"
              }
            </CustomButton>
        </div>
    </>
  )
}
      </main>

      <ModalBox open={openComment} handleClose={() => setOpenComment(false)}>
        <div className="flex flex-col gap-y-4 mt-8">
          <TextArea
            value={comment}
            placeholder="Comments..."
            size={"large"}
            className={`text-[14px]`}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="flex justify-end">
            <CustomButton onClick={handleApproveOrDecline} loading={isPending}>
              Confirm
            </CustomButton>
          </div>
        </div>
      </ModalBox>

      {contextHolder}
    </>
  );
};

export default KycProcess;
