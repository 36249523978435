import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import { ROUTES } from "../../../../../../libs/routes";
import CustomButton from "../../../../../common/CustomButton";

const KycCompleted = () => {
  const navigate = useNavigate();
  const {
    closeDrawer,
    data: { response }
  } = useDrawer();

  const handleContinue = () => {
    if(response?.goToHome){
      closeDrawer();
      navigate(ROUTES.dashboard);
    }
  };

  return (
    <>
      <main>
        <Result
          status={"success"}
          title={
            <>
              <p className="font-[500] text-[24px] text-[#2A2A29]">
                {response?.title}
              </p>
              <br />
              <p className="text-[16px] font-[300] leading-[24px]">{response?.desc}</p>
            </>
          }
          extra={
            response?.goToHome? [
            <CustomButton
            key={1}
              className={"w-full max-w-lg"}
              onClick={handleContinue}
            >
             Go To Homepage
            </CustomButton>
          ] : null}
        />
      </main>
    </>
  );
};

export default KycCompleted;