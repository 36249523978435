import React from "react";
import { useDrawer } from "../../../../../hooks/zustand_hooks/useDrawer";
import { formatNumberOnly } from "../../../../../utils/formatNumber";
import CustomButton from "../../../../common/CustomButton";




const UserLoanSummary = () => {
  const {
    openDrawer,
    updateDrawerTitle,
    data: { user_data },
  } = useDrawer();

  const handleOnComplete = () => {
      openDrawer("KYC_PROCESS", {}, "USER_LOAN_SUMMARY");
      updateDrawerTitle("KYC Process");
  };


  const detailData = user_data


  const totalRepaymentAmount = (parseFloat(detailData?.amount) * (parseFloat(detailData?.loanSetting?.interest)/100) * parseFloat(detailData?.repayment_period)) + (parseFloat(detailData?.amount))





  const loanSummaryData = [
    { label: "Customer Full Name", value: detailData?.first_name + " " + detailData?.last_name },
    { label: "Loan Amount", value: "NGN " + formatNumberOnly(detailData?.amount) },
    { label: "Interest", value: detailData?.loanSetting?.interest + "%" },
    { label: "Tenor", value: detailData?.repayment_period + " " + detailData?.repayment_type },
    { label: "Total Repayment Amount", value: "NGN " + formatNumberOnly(totalRepaymentAmount) },
    { label: "Loan Name", value: detailData?.loanSetting?.name },
  ];

  return (
    <>
      <main className="w-full max-w-lg mx-auto flex items-center justify-center mt-4">
        <div className="flex flex-col gap-y-10 w-full">
          <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm flex flex-col gap-y-8">
            <h4 className="font-[600] text-[18px] mb-2">Summary</h4>
            <div className="flex flex-col gap-y-3">
              {(loanSummaryData
              )?.map((item, index) => (
                <div key={item?.value + index} className="flex justify-between">
                  <h3 className="font-[400] text-[14px]">{item?.label}:</h3>
                  <p className="font-[275] text-[14px]">
                    {item?.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <CustomButton className={"w-full"} onClick={handleOnComplete}>
              Next
            </CustomButton>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserLoanSummary;
