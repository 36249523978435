import { useEffect } from "react";
import KycStepper from "./kyc_process/KycStepper";
import PersonalInformation from "./kyc_process/PersonalInformation";
import AddressInformation from "./kyc_process/AddressInformation";
import WorkInformation from "./kyc_process/WorkInformation";
import SelfieIdentity from "./kyc_process/SelfieIdentity";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import { useLoanKyc } from "../../../../api/mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import useWindowSize from "../../../../hooks/useWindowSize";
import { Button } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";

const CompleteKyc = ({paramID}) => {
  const {
    kyc: { currentStep },
    updateCurrentPage,
    changeStep
  } = useCustomerLoanApplication();

  const windowSize = useWindowSize({});

  const { mutate, isPending } = useLoanKyc(paramID)

  const { showMessage, contextHolder } = useCustomMessage()
  
  const makeRequest=(payload)=>{
    mutate(payload, {
      onError: (error)=>{
        const errMsg = error?.response?.data?.message || error?.message;

        showMessage("error", errMsg);
      },
      onSuccess: (res)=>{
        if(currentStep === 3){
          updateCurrentPage("KYC_DETAIL_SUMMARY", { } );
        }
        else{
          changeStep(currentStep + 1)
        }
      }
    })
    
  }

  const handleBack=()=>{
    if(currentStep > 0){
      changeStep(currentStep - 1)
    }
  }

  return (
    <>
      <main className={`mt-5 flex md:gap-x-5 gap-x-4 ${windowSize.width < 320 ? "flex-col gap-y-4": ""}`}>
        <KycStepper currentStep={currentStep} windowWidth={windowSize.width}/>
        <div className="w-full max-w-lg my-5 px-3">
          {
            currentStep > 0 && (
        <div className="mb-2">
            <Button
              type="text"
              onClick={handleBack}
              icon={<IoArrowBackOutline />}
            >
              Back
            </Button>
          </div>
            )
          }
          {currentStep === 0 ? (
            <SelfieIdentity makeRequest={makeRequest} isPending={isPending}/>
          ) : currentStep === 1 ? (
            <PersonalInformation makeRequest={makeRequest} isPending={isPending}/>
          ) : currentStep === 2 ? (
            <AddressInformation makeRequest={makeRequest} isPending={isPending}/>
          ) : currentStep === 3 ? (
            <WorkInformation makeRequest={makeRequest} isPending={isPending}/>
          ) : null}
        </div>
      </main>


      {contextHolder}
    </>
  );
};

export default CompleteKyc;
