import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import { Controller, useForm } from "react-hook-form";
import { useUserPermission } from "../../../../../../hooks/useUserPermission";
import { InputNumber } from "../../../../../common/form_inputs/Input";
import CustomButton from "../../../../../common/CustomButton";
import { Checkbox } from "../../../../../common/form_inputs/Checkbox";
import { TextArea } from "../../../../../common/form_inputs/TextArea";
import { Select } from "../../../../../common/form_inputs/Select";
import { states } from "../../../../../../libs/states";

const repayment_type = [
  { name: "day" },
  { name: "week" },
  { name: "month" },
  { name: "year" },
];

const otherSettings = [
  { value: "guarantor", label: "Request Guarantor" },
  // { value: "debit_guarantor", label: "Debit Guarantor in case of borrower’s default"},
  // { value: "work_id", label: "Request Work ID"},
  // { value: "account_statment", label: "Request bank statement" },
  // { value: "verify_nin", label: "Verify NIN"},
  // { value: "verify_bvn", label: "Verify BVN"},
  // { value: "Verify_phone", label: "Verify Phone number"},
  // { value: "check_credit_bureau", label: "Check Credit Bureau"},
];

const CreditRequirementForm = () => {
  const {
    openDrawer,
    data: { values, edit_loan_setting },
  } = useDrawer();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
    watch,
    control,
  } = useForm({
    defaultValues: {
      maximum_amount: values?.maximum_amount,
      not_applicable: values?.not_applicable,
      repayment_type: values?.repayment_type,
      repayment_period: values?.repayment_period,
      description: values?.description,
      interest: values?.interest,
      guarantor: values?.guarantor,
      debit_guarantor: values?.debit_guarantor,
      work_id: values?.work_id,
      account_statment: values?.account_statment,
      verify_nin: values?.verify_nin,
      verify_bvn: values?.verify_bvn,
      Verify_phone: values?.Verify_phone,
      check_credit_bureau: values?.check_credit_bureau,
    },
    mode: "onBlur", // Validate on blur or change
    criteriaMode: "all",
  });

  const { user_permission } = useUserPermission();

  const onSubmit = (data) => {
    openDrawer(
      "DEMOGRAPHIC_AND_LOCATION",
      { values: { ...values, ...data } },
      "CREDIT_REQUIREMENT_FORM"
    );
    // if (edit_loan_setting) {
    //   //will send the updated values to the server here and get the updates when it's successfull to return to loan setting view page
    //   console.log(data);
    //   openDrawer("LOAN_SETTING_VIEW", { loan_setting: { ...data } });
    // } else {
    //   openDrawer(
    //     "DEMOGRAPHIC_AND_LOCATION",
    //     { values: { ...values, ...data } },
    //     "CREDIT_REQUIREMENT_FORM"
    //   );
    // }
  };

  const onChangeValue = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };

  const handleKeyPress = (event) => {
    const inputValue = event.target.value;

    // Allow digits and a single decimal point
    if (
      !/[\d.]/.test(event.key) || // Block non-numeric and non-period keys
      (event.key === "." && inputValue.includes(".")) // Block multiple periods
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="flex items-center justify-center mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Credit Requirement</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label htmlFor="interest" className="font-[400] text-[14px]">
                Set Interest
              </label>
              <Controller
                name="interest"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    suffix={"%"}
                    min={0}
                    step={0.1}
                    formatter={(value) =>
                      value !== undefined && value !== null
                        ? `${value}` // Display the value as-is
                        : ""
                    }
                    parser={(value) => value?.replace(/[^\d.]/g, "")}
                    defaultValue={getValues("interest")}
                    className={`text-[14px] flex items-center ps-2 w-full text-[#033F4B] ${
                      errors.interest ? "error" : ""
                    }`}
                    status={errors.interest ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("interest");
                    }}
                    onKeyPress={(e) => {
                      // Prevent invalid characters
                      if (!/[\d.]/.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />

              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.interest && errors.interest.message}
              </small>
            </div>
            <div className="mb-3">
              <label
                htmlFor="maximum_amount"
                className="font-[400] text-[14px]"
              >
                Set Maximum Credit Amount
              </label>
              <Controller
                name="maximum_amount"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    min="10"
                    prefix={"NGN"}
                    formatter={(value) => `${value?.replace(/\D/g, "") || ""}`}
                    parser={(value) => value?.replace(/\s?|[^\d]/g, "")}
                    defaultValue={getValues("maximum_amount")}
                    className={`text-[14px] flex items-center w-full text-[#033F4B] ${
                      errors.maximum_amount ? "error" : ""
                    }`}
                    status={errors.maximum_amount ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("maximum_amount");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.maximum_amount && errors.maximum_amount.message}
              </small>
            </div>
          
            <div className="mb-2">
              <label
                htmlFor="repayment_period"
                className="font-[400] text-[14px]"
              >
                Set Maximum Repayment Period
              </label>
              <Controller
                name="repayment_period"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    // min="10"
                    formatter={(value) => `${value?.replace(/\D/g, "") || ""}`}
                    parser={(value) => value?.replace(/[^\d]/g, "")}
                    defaultValue={getValues("repayment_period")}
                    className={`text-[14px] flex items-center w-full text-[#033F4B] ${
                      errors.repayment_period ? "error" : ""
                    }`}
                    status={errors.repayment_period ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("repayment_period");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.repayment_period && errors.repayment_period.message}
              </small>
            </div>
            <div className="mb-3 flex gap-4">
              {repayment_type?.map((item, index) => (
                <Checkbox
                  key={item.name + "__duration" + index}
                  onChange={(e) => onChangeValue(item?.name, "repayment_type")}
                  checked={getValues("repayment_type") === item?.name}
                  className={"capitalize"}
                  name={item?.name}
                >
                  {item?.name}
                </Checkbox>
              ))}
            </div>

            <div className="mt-10 mb-3 grid grid-cols-1 gap-x-6 gap-y-2 items-start">
              {otherSettings?.map((item, index) => (
                <Checkbox
                  onChange={(e) => onChangeValue(e.target.checked, item?.value)}
                  defaultChecked={getValues(item?.value)}
                  checked={getValues(item?.value)}
                  name={item?.value}
                  key={index + "other_settings"}
                  className={"capitalize"}
                >
                  {item?.label}
                </Checkbox>
              ))}
            </div>
            <div className="mt-10 mb-5">
              <label htmlFor="first_name" className="font-[400] text-[14px]">
                Additional Information(optional)
              </label>
              <TextArea
                name="description"
                defaultValue={getValues("description")}
                placeholder="Enter Additional information"
                size={"large"}
                className={`text-[14px] ${errors.description ? "error" : ""}`}
                status={errors.description ? "error" : ""}
                {...register("description", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e.target.value, "description")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.description && errors.description.message}
              </small>
            </div>
            <div className="mt-6 flex justify-center">
              <CustomButton className={"w-full"} type="submit">
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreditRequirementForm;
