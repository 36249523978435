import { ConfigProvider, Steps } from "antd";

const KycStepper = ({ currentStep, windowWidth }) => {


  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#733534",
            lineWidth: 3,
          },
          components: {
            Steps: {
              vertical: {
                size: "large",
              },
              titleLineHeight: 50,
            },
          },
        }}
      >
        <div className="flex flex-col h-screen p-4">
          <Steps
            direction={windowWidth < 320 ? "horizontal": "vertical"}
            current={currentStep}
            className="flex-grow"
            items={[
              { title: "", key: "step-1" },
              { title: "", key: "step-2" },
              { title: "", key: "step-3" },
              { title: "", key: "step-3" },
            ]}
          />
        </div>
      </ConfigProvider>
    </>
  );
};

export default KycStepper;
