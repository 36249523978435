import React, { useRef, useState, useEffect } from "react";
import { Input } from "../../../common/form_inputs/Input";
import { TextArea } from "../../../common/form_inputs/TextArea";
import { useForm } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useCreatePlan } from "../../../../api/admin_mutation";
import { useUploadImage } from "../../../../api/mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { Select } from "../../../common/form_inputs/Select";

export default function CreatePlan() {
  //external hooks
  const {
    data: { plan },
    closeDrawer,
  } = useDrawer();

  const mutation = useCreatePlan(plan ? true : false, plan?.id);

  const uploadImageMutation = useUploadImage();

  const { showMessage, contextHolder } = useCustomMessage();

  //

  const [isLoading, setIsLoading] = useState(false);


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    watch,
  } = useForm({
    values: {},
  });

  useEffect(() => {
    reset({
      ...plan,
    });
  }, [plan, reset]);



  const fileInputRef = useRef(null);

  const onsubmit = async (data) => {

    const json = {
      ...data
    };



    setIsLoading(true);

    if (data?.image instanceof File) {
      const formData = new FormData();
      formData.append("image", data?.image);
      try {
        const res = await uploadImageMutation.mutateAsync(formData);
        json.image = res?.data?.data;
      } catch (error) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        showMessage("error", errMsg);
        // Stop further execution
        return;
      }
    }

    

    mutation.mutate(json, {
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
      onSuccess: (data) => {
        const res = data?.data;

        reset({
          name: "",
          check_balance: "",
          number_of_mandate: "",
          number_of_business_mandate: "",
          transaction_fee: "",
          debit_fee: "",
          number_of_debit: "",
          number_of_admin: "",
          description: "",
          plan_type: "",
          image: ""
        });

        fileInputRef.current = null

        showMessage("success", res?.message);
        closeDrawer();
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  const handleChange = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName);
  };

  return (
    <>
      <main className="w-full max-w-lg mx-auto">
        <form action="" onSubmit={handleSubmit(onsubmit)}>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Name
            </label>
            <Input
              name="name"
              value={watch("name")}
              placeholder="Enter Plan Name"
              size={"large"}
              className={` text-[14px] ${errors.name ? "error" : ""}`}
              status={errors.name ? "error" : ""}
              {...register("name", {
                required: "Plan Name is required",
              })}
              onChange={(e) => handleChange(e.target.value, "name")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.name && errors.name.message}
            </small>
          </div>
          <div className="mb-3 grid grid-cols-2 gap-4">
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of Mandate
              </label>
              <Input
                name="number_of_mandate"
                value={watch("number_of_mandate")}
                type="number"
                placeholder="Enter number of mandate"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_mandate ? "error" : ""
                }`}
                status={errors.number_of_mandate ? "error" : ""}
                {...register("number_of_mandate", {
                  required: "Number of mandate is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_mandate")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_mandate && errors.number_of_mandate.message}
              </small>
            </div>
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of Admin
              </label>
              <Input
                name="number_of_admin"
                value={watch("number_of_admin")}
                type="number"
                placeholder="Enter number of admin"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_admin ? "error" : ""
                }`}
                status={errors.number_of_admin ? "error" : ""}
                {...register("number_of_admin", {
                  required: "Number of admin is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_admin")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_admin && errors.number_of_admin.message}
              </small>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Number of Business Mandate
            </label>
            <Input
              name="number_of_business_mandate"
              value={watch("number_of_business_mandate")}
              type="number"
              disabled={true}
              placeholder="Enter number of business mandate"
              size={"large"}
              className={` text-[14px] ${
                errors.number_of_business_mandate ? "error" : ""
              }`}
              status={errors.number_of_business_mandate ? "error" : ""}
              {...register("number_of_business_mandate", {
                // required: "Number of business mandate is required",
              })}
              onChange={(e) =>
                handleChange(e.target.value, "number_of_business_mandate")
              }
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.number_of_business_mandate &&
                errors.number_of_business_mandate.message}
            </small>
          </div>

          <div className="mb-3 grid grid-cols-2 gap-4">
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Transaction Fee%
              </label>
              <Input
                name="transaction_fee"
                value={watch("transaction_fee")}
                type="number"
                placeholder="Enter Transaction Fee"
                size={"large"}
                className={` text-[14px] ${
                  errors.transaction_fee ? "error" : ""
                }`}
                status={errors.transaction_fee ? "error" : ""}
                {...register("transaction_fee", {
                  required: "Transaction fee is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "transaction_fee")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.transaction_fee && errors.transaction_fee.message}
              </small>
            </div>
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Debit Fee
              </label>
              <Input
                name="debit_fee"
                value={watch("debit_fee")}
                type="number"
                placeholder="Enter debit fee"
                size={"large"}
                className={` text-[14px] ${errors.debit_fee ? "error" : ""}`}
                status={errors.debit_fee ? "error" : ""}
                {...register("debit_fee", {
                  required: "Debit fee is required",
                })}
                onChange={(e) => handleChange(e.target.value, "debit_fee")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.debit_fee && errors.debit_fee.message}
              </small>
            </div>
          </div>
          <div className="mb-3 grid grid-cols-2 gap-4">
            {/* <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Check Balance
              </label>
              <Input
                name="check_balance"
                value={watch("check_balance")}
                type="number"
                placeholder="Enter number to check balance"
                size={"large"}
                className={` text-[14px] ${
                  errors.check_balance ? "error" : ""
                }`}
                status={errors.check_balance ? "error" : ""}
                {...register("check_balance", {
                  required: "Number to check balance is required",
                })}
                onChange={(e) => handleChange(e.target.value, "check_balance")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.check_balance && errors.check_balance.message}
              </small>
            </div> */}
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of Debit
              </label>
              <Input
                name="number_of_debit"
                value={watch("number_of_debit")}
                type="number"
                placeholder="Enter number of debit"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_debit ? "error" : ""
                }`}
                status={errors.number_of_debit ? "error" : ""}
                {...register("number_of_debit", {
                  required: "Number of debit is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_debit")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_debit && errors.number_of_debit.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of BVN
              </label>
              <Input
                name="number_of_bvn"
                value={watch("number_of_bvn")}
                type="number"
                placeholder="Enter number of bvn"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_bvn ? "error" : ""
                }`}
                status={errors.number_of_bvn ? "error" : ""}
                {...register("number_of_bvn", {
                  // required: "Number of bvn is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_bvn")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_bvn && errors.number_of_bvn.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of NIN
              </label>
              <Input
                name="number_of_nin"
                value={watch("number_of_nin")}
                type="number"
                placeholder="Enter number of nin"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_nin ? "error" : ""
                }`}
                status={errors.number_of_nin ? "error" : ""}
                {...register("number_of_nin", {
                  // required: "Number of nin is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_nin")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_nin && errors.number_of_nin.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of Address
              </label>
              <Input
                name="number_of_address"
                value={watch("number_of_address")}
                type="number"
                placeholder="Enter number of address"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_address ? "error" : ""
                }`}
                status={errors.number_of_address ? "error" : ""}
                {...register("number_of_address", {
                  // required: "Number of address is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_address")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_address && errors.number_of_address.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of Credit
              </label>
              <Input
                name="number_of_credit"
                value={watch("number_of_credit")}
                type="number"
                placeholder="Enter number of credit"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_credit ? "error" : ""
                }`}
                status={errors.number_of_credit ? "error" : ""}
                {...register("number_of_credit", {
                  // required: "Number of credit is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_credit")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_credit && errors.number_of_credit.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of Loan
              </label>
              <Input
                name="number_of_loan"
                value={watch("number_of_loan")}
                type="number"
                placeholder="Enter number of loan"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_loan ? "error" : ""
                }`}
                status={errors.number_of_loan ? "error" : ""}
                {...register("number_of_loan", {
                  // required: "Number of loan is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_loan")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_loan && errors.number_of_loan.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of Links
              </label>
              <Input
                name="number_of_links"
                value={watch("number_of_links")}
                type="number"
                placeholder="Enter number of links"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_links ? "error" : ""
                }`}
                status={errors.number_of_links ? "error" : ""}
                {...register("number_of_links", {
                  // required: "Number of links is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_links")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_links && errors.number_of_links.message}
              </small>
            </div>
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Plan Type
              </label>
              <Select
                value={watch("plan_type")}
                showSearch
                placeholder="Select plan type"
                className="w-full"
                optionFilterProp="label"
                status={errors.plan_type ? "error" : ""}
                {...register("plan_type", {
                  required: "Plan Type is required",
                })}
                onChange={(value) => {
                  setValue("plan_type", value);
                  trigger("plan_type");
                }}
                options={[
                  { label: "Month", value: "monthly" },
                  { label: "Quarter", value: "quarterly" },
                  { label: "Year", value: "yearly" },
                ]}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.plan_type && errors.plan_type.message}
              </small>
            </div>
          </div>

          <div className="mb-3 flex flex-col col-span-2">
            <label htmlFor="" className="font-[400] text-[14px]">
              Business description
            </label>
            <TextArea
              value={watch("description")}
              rows={2}
              placeholder="Business description"
              {...register("description")}
              onChange={(e) => handleChange(e.target.value, "description")}
            />
          </div>

          <div className="mb-3 flex flex-col col-span-2">
            <label htmlFor="" className="font-[400] text-[14px]">
              Plan Image
            </label>
            <Input
              type={"file"}
              size="large"
              ref={fileInputRef}
              onChange={(e) => handleChange(e.target.files[0], "image")}
            />
          </div>

          <div className="mb-3">
            <CustomButton
              type={"submit"}
              className={"w-full"}
              loading={isLoading}
            >
            {
              plan? "Update Plan" : "Create Plan"
            }
            </CustomButton>
          </div>
        </form>
      </main>

      {contextHolder}
    </>
  );
}
