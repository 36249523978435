import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import EmandateSidebar from "../../specific/admin_side_loan/sidebar/EmandateSidebar";
import useWindowSize from "../../../hooks/useWindowSize";
import "./emandateLayout.css";
import HeaderContent from "../../specific/admin_side_loan/sidebar/HeaderContent";
import DashboardCreateButton from "../../specific/admin_side_loan/dashboardTask/DashboardCreateButton";
import { useProfileData } from "../../../hooks/useProfileData";

export default function EmandateLayout() {
  const [navIsOpen, setNavIsOpen] = useState(true);
  const windowSize = useWindowSize({});


  const { isAdmin } = useProfileData();


  const handleOpenNav = (param) => {
    setNavIsOpen(param);
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      setNavIsOpen(false);
    }
  }, []);

  return (
    <>
      <main style={{ backgroundColor: "#FFFFFF" }}>
        <EmandateSidebar visible={navIsOpen} show={handleOpenNav} />
        <section
          className={`py-5 md:!px-5 !px-3 ${
            !navIsOpen ? "page no_sidebar" : "page page-with-navbar"
          }`}
          onClick={() => {
            if (windowSize.width < 768) {
              navIsOpen && handleOpenNav(false);
            }
          }}
        >
            <div className="md:hidden flex mb-4">
          <HeaderContent />
            </div>
          <Outlet />
        </section>


{
  !isAdmin && (
    <DashboardCreateButton />
  )
}

      </main>
    </>
  );
}
