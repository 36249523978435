import React from "react";
import { useDrawer } from "../../../../../hooks/zustand_hooks/useDrawer";
import { formatNumberOnly } from "../../../../../utils/formatNumber";
import CustomButton from "../../../../common/CustomButton";

const LoanSettingView = () => {
  const {
    openDrawer,
    updateDrawerTitle,
    data: { loan_setting },
  } = useDrawer();

  const handleEdit = () => {
    openDrawer(
      "CREDIT_REQUIREMENT_FORM",
      { values: { ...loan_setting }, edit_loan_setting: true },
      "LOAN_SETTING_VIEW"
    );
    updateDrawerTitle("Edit Loan Setting");
  };

  const loanSettingSummary = [
    { label: "Loan Label", value: loan_setting?.name },
    { label: "Interest", value: loan_setting?.interest + "%" },
    {
      label: "Maximum credit Amount",
      value: "NGN " + formatNumberOnly(loan_setting?.maximum_amount),
    },
    {
      label: "Maximum repayment period",
      value: loan_setting?.repayment_period,
    },
    loan_setting?.age_range && {
      label: "Age Range",
      value: loan_setting?.age_range,
    },
    loan_setting?.states && {
      label: "States",
      value: loan_setting?.states,
    },
    {
      label: "Repayment Tenor",
      value:
        loan_setting?.repayment_period + " " + loan_setting?.repayment_type,
    },
    {
      label: "Request Guarantor",
      value: loan_setting?.guarantor ? "Yes" : "No",
    },
    {
      label: "Request Account Statement",
      value: loan_setting?.account_statment ? "Yes" : "No",
    },
    {
      label: "Request Guarantor",
      value: loan_setting?.guarantor ? "Yes" : "No",
    },
  ]?.filter(Boolean);


  return (
    <>
      <main className="w-full max-w-lg mx-auto flex items-center justify-center mt-4">
        <div className="flex flex-col gap-y-10 w-full">
          <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm flex flex-col gap-y-3">
            <h4 className="font-[600] text-[18px] mb-2">
              Loan Setting Summary
            </h4>
            <div className="flex flex-col gap-y-3">
              {loanSettingSummary?.map((item, index) => (
                <div key={item?.value+"____detail"+ index} className="flex justify-between">
                  <h3 className="font-[400] text-[14px]">{item?.label}:</h3>
                  <p className="font-[275] text-[14px]">{
                    typeof item?.value==="object"? item?.value?.join(", ") : item?.value
                  }</p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <CustomButton className={"w-full"} onClick={handleEdit}>
              Edit
            </CustomButton>
          </div>
        </div>
      </main>
    </>
  );
};

export default LoanSettingView;
