import React from "react";
import { useForm } from "react-hook-form";
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import { Input } from "../../../../../common/form_inputs/Input";
import CustomButton from "../../../../../common/CustomButton";

const ApplicationLabel = () => {


    const { openDrawer, data: { values }} = useDrawer();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        trigger
      } = useForm({
        defaultValues: {
          application_label: values?.application_label,
        },
      });

      const onSubmit = (data) => {
        openDrawer("CREDIT_REQUIREMENT_FORM", { values: {...data} }, "APPLICATION_LABEL_FORM");
      };


  return (
    <>
      <main className="w-full max-w-lg mx-auto flex items-center justify-center mt-4">
          <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm flex flex-col gap-y-8">
            <h4 className="font-[600] text-[18px] mb-2">
              Credit Application label
            </h4>
            <form className="flex flex-col gap-y-10" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label htmlFor="application_label" className="font-[400] text-[14px]">
                What would you label this application link?
                </label>
                <Input
                  name="application_label"
                  defaultValue={getValues("application_label")}
                  placeholder="Enter customer application label"
                  size={"large"}
                  className={`text-[14px] ${errors.application_label ? "error" : ""}`}
                  status={errors.application_label ? "error" : ""}
                  {...register("application_label", {
                    required: "application label is required",
                  })}
                  onChange={(e) =>{
                    setValue("application_label", e.target.value)
                    trigger("application_label")
                  }}
                />
                <small style={{ fontSize: "13px" }} className="text-red-500">
                  {errors.application_label && errors.application_label.message}
                </small>
              </div>
              <div className="mt-6 flex justify-center">
              <CustomButton
                className={"w-full"}
                type={'submit'}
              >
                Continue
              </CustomButton>
            </div>
            </form>
          </div>
      </main>
    </>
  );
};

export default ApplicationLabel;
