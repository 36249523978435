
import { useState } from "react";
import { HiOutlineCamera } from "react-icons/hi2";
import CustomButton from "../../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import useCustomMessage from "../../../../../hooks/zustand_hooks/useCustomMessage";
import { useUploadImage } from "../../../../../api/mutation";

const SelfieIdentity = ({makeRequest, isPending}) => {

    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

const {
    updateData,
    changeStep,
    data: { form_values, userDetail }
  } = useCustomerLoanApplication();

  const uploadImageMutation = useUploadImage();

  const { showMessage, contextHolder } = useCustomMessage();



  const handleChoosePhoto = (e) => {
    const sFile = e.target.files[0];

    if(sFile){
      const fileUrl = URL.createObjectURL(sFile)
      setPreviewUrl(fileUrl);
      setFile(sFile);

    }
    

  };



  const handleContinue=async()=>{
    if(file){
        const formData = new FormData();
        formData.append("image", file);
        setIsLoading(true)
        try {
          const res = await uploadImageMutation.mutateAsync(formData);
          console.log(res?.data?.data)

          // payload.profile_picture = res?.data?.data?.url;
          updateData({ form_values: { ...form_values, profile_picture: res?.data?.data}})
          await makeRequest({
            profile_picture: res?.data?.data
          })


        } catch (error) {
          const errMsg = error?.response?.data?.message || error?.message;
          showMessage("error", errMsg);
          // Stop further execution
          return;
        }
        finally{
          setIsLoading(false)
        }
    }
    else if(userDetail?.profile_picture){
      changeStep(1)
    }
    else{
        showMessage("error", "Please upload a photo");
        return;
    }

    // changeStep(1);
  }

  return (
    <>
      <main className="flex flex-col gap-y-5">
        <div>
          <h3 className="font-[500] text-[16px]">Complete KYC Process</h3>
          <p className="font-[300] text-[14px]">
            You won’t have to do this in your next loan application
          </p>
        </div>

        <div className="">
          <h3 className="font-[500] text-[16px] text-center">Take A Selfie</h3>
          <div className="border border-[#DBDBDB] rounded-[10px] h-52 w-56 mx-auto mt-3 flex justify-center items-center"
          style={{
            backgroundImage: (previewUrl || userDetail?.profile_picture) ? `url(${previewUrl || userDetail?.profile_picture})` : 'none',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          >
            <label
              htmlFor="upload_photo"
              className="flex flex-col items-center cursor-pointer"
              
            >
              <input
                type="file"
                className="hidden"
                id="upload_photo"
                accept=".jpg, .png, .jpeg, .gif"
                onChange={handleChoosePhoto}
              />

              <HiOutlineCamera size={40} />
              <p className="font-[300] text-[16px]">Click to upload Photo</p>
            </label>
          </div>
        </div>
        <div>
            <CustomButton onClick={handleContinue} className={"w-full"}
            loading={isLoading || isPending}
            >Continue</CustomButton>
        </div>
      </main>
      {contextHolder}
    </>
  );
};

export default SelfieIdentity;
