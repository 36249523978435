import { ConfigProvider, Pagination, Radio, Result } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useUserPermission } from "../../../hooks/useUserPermission";
import { exportExcel } from "../../../utils/exportExcel";
import moment from "moment";
import { useGetLoanRequest, useGetLoanSetting } from "../../../api/admin_mutation";
import { Spin } from "../../../components/common/form_inputs/Spin";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import { Select } from "../../../components/common/form_inputs/Select";
import { Input } from "../../../components/common/form_inputs/Input";
import LoanApplicationTable from "../../../components/common/table/loan_application/LoanApplicationTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoanSettingTable from "../../../components/common/table/loan_application/LoanSettingTable";
import { useGetLoanRequestQuery } from "../../../api/getHooks";

const LoanApplication = () => {

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState(["pending", "approved", "declined"])



  const [ searchParams] = useSearchParams();
  
    const tab = searchParams.get("tab")
  
    const navigate = useNavigate({});

    const [currentData, setCurrentData] = useState([]);

    const { openDrawer, updateDrawerTitle } = useDrawer()

  const {
    user_permission,
    isLoading: permissionLoading,
    isError: permissionError,
  } = useUserPermission();


  // const { mutate, data, isPending: loanRequestLoading, isError:loanRequestError } = useGetLoanRequest();


  const {data, isLoading: loanRequestLoading, isError: loanRequestError} = useGetLoanRequestQuery(
    {
      page,
      status
    })


  const { mutate:mutateLoanSetting, data: loanSettingData, isPending: loadnSettingLoading, isError: loanSettingError } = useGetLoanSetting();


  const isError = tab==="loan_setting"? loanSettingError : loanRequestError
  const isLoading = tab==="loan_setting"? loadnSettingLoading : loanRequestLoading

  // useEffect(() => {
  //   mutate({
  //     page,
  //     status
  //   });
    
  // }, [mutate, page, status]);

  useEffect(() => {
    mutateLoanSetting()
    
  }, [mutateLoanSetting]);



  const responseData = tab==="loan_setting"? loanSettingData?.data : data?.data?.data


    useEffect(() => {
    if (!isLoading && responseData?.data) {
      setCurrentData(responseData?.data);
    }
  }, [isLoading, responseData]);

  

  const tableData = useMemo(() => {
    return responseData?.data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [responseData]);


  // Set the locale to start the week on Monday
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  const handleChange = (value) => {
    if(value==="all"){
      setStatus(["pending", "approved", "declined"]);
    }else{
      setStatus([value]);
    }
  };




  useEffect(() => {
    const filterData = () => {
      if (searchValue?.trim()) {
        const value = searchValue?.toLowerCase();
        const updatedData = tableData?.filter((item) => {
          const matches = tab==="loan_setting" ? [item?.name?.toLowerCase()].some((field) =>
            field?.includes(value)
          ) : [item?.first_name?.toLowerCase()].some((field) =>
            field?.includes(value)
          );
          return matches;
        });

        setFilteredData(updatedData?.length ? updatedData : []);
      } else {
        // If search is cleared, show full data
        setFilteredData(tableData);
      }
    };

    filterData();
  }, [searchValue, tab, tableData]);

  const tableRows =
    searchValue?.length || selectedFilter?.length ? filteredData : currentData;


  const exportAs = (value) => {
    exportExcel({
      excelData: tableData,
      fileName: `transactions`,
    });
  };


  const handleOpenDrawer = (data) => {
    openDrawer("USER_LOAN_SUMMARY", { user_data: data });
    updateDrawerTitle("Loan Application");
  };


  const handleViewLoanSetting = (data) => {
    openDrawer("LOAN_SETTING_VIEW", { loan_setting: data });
    updateDrawerTitle("Loan Setting");
  };



  const handleTabChange = (e) => {
    const tab = e.target.value;

    const params = new URLSearchParams(window.location.search);
    params.set('tab', tab); // Set your search parameter here
    navigate({ search: params.toString() });
  };

//=================== HANDLE PAGINATION CHANGE ===============
  const handlePaginateChange = (page) => {
    setPage(page)
  };
//======================================================




  return (
    <>
      <main className="mt-3 mb-16">
        <ConfigProvider
        theme={{
            token: {
                colorPrimary: "#733534",
            },
          components: {
            Radio: {
              buttonSolidCheckedActiveBg: "#733534",
              buttonSolidCheckedBg: "#733534",
              buttonSolidCheckedHoverBg: "#733534",
              buttonSolidCheckedHoverColor: "#733534",
            },
          },
        }}

      >
        <Radio.Group
          options={[
            {
              label: "Loan Request",
              value: "loan_request",
            },
            {
              label: "Loan Setting",
              value: "loan_setting",
            },
          ]}
          onChange={handleTabChange}
          value={tab}
          style={{
            marginBottom: 8,
          }}
          size="large"
          optionType="button"
          buttonStyle="solid"
          className="rounded-[8px] items-center"
         />
      </ConfigProvider>

       
        <div className="flex gap-x-3 mt-6 mb-3 justify-between items-end">
          <Input
            placeholder={tab==="loan_setting"? "Search for Loan Name" :"Search Name"}
            style={{
              width: 250,
            }}
            onChange={(e) => setSearchValue(e.target.value)}
            prefix={
              <IoSearchOutline
                size={"16px"}
                style={{
                  color: "rgba(0,0,0,.25)",
                }}
              />
            }
          />
          <div className="flex gap-x-2">
           
            <Select
              size="small"
              defaultValue="all"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: "all", label: "All Status" },
                { value: "pending", label: "Pending" },
                { value: "approved", label: "Approved" },
                { value: "declined", label: "Declined" },
              ]}
            />
            <Select
              size="small"
              placeholder="Export as"
              style={{
                width: 120,
              }}
              onChange={exportAs}
              options={[
                {
                  value: "excel",
                  label: "Export as excel",
                },
              ]}
            />
          </div>
        </div>

        {
       isLoading && !tableRows?.length ? (
        <div className="flex justify-center">
          <Spin />
        </div>
      ): 
        isError ? (
          <Result
            status="error"
            title="Unexpected error occurred"
            extra={null}
          />
        ) : permissionLoading ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ) : permissionError ? (
          <Result
            status="error"
            title="Unexpected error occurred"
            extra={null}
          />
        ) : user_permission?.view_transactions ? (
          <div className="">
                {
                  tab==="loan_setting"? (
                    <LoanSettingTable rows={tableRows} handleOpen={handleViewLoanSetting}/>
                  ): (
                    <>
                    <LoanApplicationTable rows={tableRows} handleOpen={handleOpenDrawer} />
                    
                    </>
                  )
                }
                {isLoading && (
                  <div className="flex justify-center">
                    <Spin />
                  </div>
                )}

                {
                  responseData?.totalRecords && 
                  (
                  <div className="mt-2">
                    <Pagination
                      current={responseData?.currentPage}
                      align={"end"}
                      total={responseData?.totalRecords}
                      onChange={handlePaginateChange}
                      pageSize={20}
                    />
                  </div>
                  )
                }
              </div>
        ) : (
          <div className="text-center my-10">
            <i className="text-lg">
              You are not permitted to view transactions
            </i>
          </div>
        )}
      </main>
    </>
  );
};


export default LoanApplication