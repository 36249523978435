import "./sidebar.css";
import { useCallback, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "../../../../hooks/useWindowSize";
import {
  CollectionIcon,
  MenuIcon,
  RefreshIcon,
  SettingIcon,
} from "../../../../utils/svg_icons";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import { FaUserFriends } from "react-icons/fa";
import { ROUTES } from "../../../../libs/routes";
import { useProfileData } from "../../../../hooks/useProfileData";
import { logoutFn } from "../../../../logout";
import CustomButton from "../../../common/CustomButton";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IoIosLogOut } from "react-icons/io";
import { MdSubscriptions } from "react-icons/md";
import enlace from "../../../../assets/white_enlace.png";
import HeaderContent from "./HeaderContent";
import { CgCalendarDue } from "react-icons/cg";
import { decryptData } from "../../../../utils/cryptData";
import { GiTakeMyMoney } from "react-icons/gi";

export default function EmandateSidebar({ visible, show }) {
  const { data, isAdmin } = useProfileData();

  const menus = [
    { path: ROUTES.mandate_status, name: "Mandate Status", icon: RefreshIcon },
    {
      path: isAdmin ? ROUTES.users : ROUTES.customers,
      name: isAdmin ? "Users" : "Customers",
      icon: FaUserFriends,
    },
    {
      path: isAdmin ? ROUTES.collection : ROUTES.transaction,
      name: isAdmin ? "Collection" : "Transaction",
      icon: CollectionIcon,
    },
    {
      path: ROUTES.due_loans,
      name: "Due Loans",
      icon: CgCalendarDue,
    },
    {
      path: ROUTES.loan_application,
      name: "Loan Application",
      icon: GiTakeMyMoney,
    },
  ];

  const screenWidth = useWindowSize().width;

  const activePath = useLocation().pathname;

  const navigate = useNavigate();

  const [modal, contextHolder] = Modal.useModal({});

  const current_page_title = activePath.split("/").pop()?.replace(/_/g, " ");

  useEffect(() => {
    if (screenWidth >= 900) {
      show(true);
    }
  }, [screenWidth, show]);

  const confirmLogout = () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to proceed to logout",

      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <CustomButton
            size={"middle"}
            onClick={() => {
              logoutFn();
              navigate(ROUTES.login);
            }}
          >
            Confirm
          </CustomButton>
        </>
      ),
    });
  };

  const getInitials = useCallback((name) => {
    const words = name?.trim()?.split(" ");

    // If there is only one word, return the first two letters.
    if (words?.length === 1) {
      return words?.[0]?.substring(0, 2)?.toUpperCase();
    }

    // If there are multiple words, return the first letter of each.
    return words
      ?.map((word) => word[0]) // Get the first letter of each word.
      ?.join("")
      ?.toUpperCase();
  }, []);

  const mdScreenRange = useMemo(() => {
    return screenWidth < 900 && screenWidth > 450;
  }, [screenWidth]);



  return (
    <>
      <div className="emandate_mobile-nav h-screen lg:ps-[20%] md:py-3 py-8 ps-3 md:ps-0 pe-3 flex justify-between md:shadow-sm shadow">
        <div
          className={`flex items-center w-full ${
            mdScreenRange && !visible ? "ms-[10vh]" : ""
          }`}
        >
          {screenWidth < 451 && (
            <button className="mobile-nav-btn" onClick={() => show(!visible)}>
              <MenuIcon clicked={visible} />
            </button>
          )}
          <div className="flex justify-between flex-wrap w-full md:ps-5 ps-8">
            <p className="font-[600] capitalize">{current_page_title}</p>
            <div className="md:flex hidden">
              <HeaderContent />
            </div>
          </div>
        </div>
      </div>
      <nav className={`emandate_sidebar_nav ${visible ? "" : "sidebar"}`}>
        <div className="flex flex-col mt-5">
          <div className="flex justify-between px-3">
            <div className="flex gap-3 items-center pb-3 ps-">
              <div
                style={{
                  height: "46px",
                  width: "46px",
                  borderRadius: "50%",
                  backgroundColor: "#F9D7D68F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#733534",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                {getInitials(decryptData(data?.company?.name))}
              </div>
              <div className="flex flex-col gap-1">
                <p
                  className="font-bold my-auto line-clamp-1"
                  style={{ fontSize: "16px" }}
                >
                  {decryptData(data?.company?.name) ?? ""}
                </p>
                <p className="font-medium my-auto text-[12px] line-clamp-1">
                  ID: {decryptData(data?.company?.company_id)}
                </p>
              </div>
            </div>
            {screenWidth < 900 && (
              <button className="mobile-nav-btn" onClick={() => show(!visible)}>
                <MenuIcon clicked={visible} />
              </button>
            )}
          </div>
          <hr className="border-0 h-[1px] bg-[#7D83984D] my-3" />
          <div
            className={`flex flex-col ${
              mdScreenRange && !visible ? "px-1" : "px-3"
            }`}
          >
            <Link
              to={ROUTES.dashboard}
              className={`p-3 rounded no-underline text-white flex ${
                mdScreenRange && !visible ? "flex-row-reverse" : ""
              } items-center gap-2 `}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                backgroundColor:
                  activePath === ROUTES.dashboard ? "#733534" : "",
                width: "100%",
                fontWeight: 400,
              }}
              // onClick={() => {
              //   window.innerWidth < 768 && show(!visible);
              // }}
            >
              <RiDashboardHorizontalFill
                size={"3vh"}
                sx={{ color: "#ACA9BB" }}
              />
              {mdScreenRange && !visible ? "" : "Dashboard"}
            </Link>
          </div>
          <hr className="border-0 h-[1px] bg-[#7D83984D] my-3" />
          <div
            className={`flex flex-col mt-3 mb-3 ${
              mdScreenRange && !visible ? "px-1" : "px-3"
            }`}
          >
            {menus?.map((item, index) => (
              <Link
                key={index + "__menu__"}
                to={item?.path}
                className={`p-3 rounded no-underline text-white flex ${
                  mdScreenRange && !visible ? "flex-row-reverse" : ""
                } items-center gap-2 `}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  backgroundColor: item?.path.includes(activePath) ? "#733534" : "",
                  width: "100%",
                  fontWeight: 400,
                }}
                // onClick={() => {
                //   window.innerWidth < 768 && show(!visible);
                // }}
              >
                <item.icon size={"3vh"} style={{ color: "#ACA9BB" }} />
                {mdScreenRange && !visible ? "" : item?.name}
              </Link>
            ))}
          </div>
          <hr className="border-0 h-[1px] bg-[#7D83984D] my-3" />
          <div
            className={`flex flex-col mt-3 mb-3 ${
              mdScreenRange && !visible ? "px-1" : "px-3"
            }`}
          >
            <Link
              to={ROUTES.settings}
              className={`p-3 rounded no-underline !text-white flex ${
                mdScreenRange && !visible ? "flex-row-reverse" : ""
              } items-center gap-2 `}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                backgroundColor: activePath?.includes("settings")
                  ? "#733534"
                  : "",
                width: "100%",
                fontWeight: 400,
              }}
              onClick={() => {
                window.innerWidth < 768 && show(!visible);
              }}
            >
              <SettingIcon size={"3.5vh"} sx={{ color: "#ACA9BB" }} />
              {mdScreenRange && !visible ? "" : "Setting"}
            </Link>
          </div>
          {!isAdmin && (
            <div
              className={`flex flex-col mb-3 ${
                mdScreenRange && !visible ? "px-1" : "px-3"
              }`}
            >
              <Link
                to={ROUTES.wallet_and_subscription}
                className={`p-3 rounded no-underline text-white flex ${
                  mdScreenRange && !visible ? "flex-row-reverse" : ""
                } items-center gap-2 `}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  backgroundColor: activePath?.includes(
                    "wallet_and_subscription"
                  )
                    ? "#733534"
                    : "",
                  width: "100%",
                  fontWeight: 400,
                }}
                onClick={() => {
                  window.innerWidth < 768 && show(!visible);
                }}
              >
                <MdSubscriptions size={"3vh"} sx={{ color: "#ACA9BB" }} />
                {mdScreenRange && !visible ? "" : "Wallet & Subscription"}
              </Link>
            </div>
          )}
          <div
            className={`flex flex-col mt- mb-3 ${
              mdScreenRange && !visible ? "px-1" : "px-3"
            }`}
          >
            <div
              className={`p-3 rounded no-underline text-white flex ${
                mdScreenRange && !visible ? "flex-row-reverse" : ""
              } items-center gap-2 cursor-pointer`}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                width: "100%",
                fontWeight: 400,
              }}
              onClick={confirmLogout}
            >
              <IoIosLogOut size={"3vh"} sx={{ color: "#ACA9BB" }} />
              {mdScreenRange && !visible ? "" : "Logout"}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <img
            src={enlace}
            alt="enlace logo"
            className="object-cover cursor-pointer"
            width={100}
            height={100}
          />
        </div>
      </nav>

      {contextHolder}
    </>
  );
}
