import React, { useEffect } from "react";
import avatar from "../../../../../assets/avatar.png";
import { Image, Radio } from "antd";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../../common/form_inputs/Input";
import CustomButton from "../../../../common/CustomButton";
import { Select } from "../../../../common/form_inputs/Select";
import { DatePicker } from "../../../../common/form_inputs/DatePicker";
import { useCustomerLoanApplication } from "../../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const PersonalInformation = ({ makeRequest, isPending, updateForm }) => {
  const {
    data: { form_values, userDetail, loan_setting },
    updateData,
  } = useCustomerLoanApplication();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      gender: userDetail?.bioData?.gender,
      maritalStatus: userDetail?.bioData?.maritalStatus,
      dob: userDetail?.bioData?.dob,
      bvn: userDetail?.bioData?.bvn,
      age_range: userDetail?.bioData?.age_range,
    },
  });

  useEffect(() => {
    reset({
      gender: userDetail?.bioData?.gender,
      maritalStatus: userDetail?.bioData?.maritalStatus,
      dob: userDetail?.bioData?.dob,
      bvn: userDetail?.bioData?.bvn,
      age_range: userDetail?.bioData?.age_range,
    });
  }, [
    reset,
    userDetail?.bioData?.bvn,
    userDetail?.bioData?.dob,
    userDetail?.bioData?.gender,
    userDetail?.bioData?.maritalStatus,
    userDetail?.bioData?.age_range,
  ]);

  const onSubmit = async (data) => {
    const payload = {
      bioData: {
        gender: data?.gender,
        maritalStatus: data?.maritalStatus,
        dob: data?.dob,
        bvn: data?.bvn,
      },
    };

    await makeRequest(payload);

    updateData({ form_values: { ...form_values, ...data } });
  };

  const handleKeyPress = (event) => {
    const inputValue = event.target.value;

    if (
      !/[\d.]/.test(event.key) || // Block non-numeric and non-period keys
      (event.key === "." && inputValue.includes(".")) // Block multiple periods
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <main className="flex flex-col gap-y-5 my-5">
        <h3 className="font-[500] text-[16px] text-center">
          Personal Information
        </h3>
        {!updateForm && (
          <div className="flex justify-center mb-8">
            <Image
              preview={false}
              className="rounded-lg border border-[#908A8A]"
              width={200}
              src={form_values?.profile_picture || avatar}
            />
          </div>
        )}
        <div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Age Range*
              </label>
              <div>

              <Radio.Group
                onChange={(e) => {
                  setValue("age_range", e.target.value);
                  trigger("age_range");
                }}
                value={watch("age_range")}
              >
                {userDetail?.loanSetting?.age_range?.map((age, index) => (
                  <Radio value={age} key={age + "___age" + index}>{age}</Radio>
                ))}
              </Radio.Group>
</div>
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.age_range && errors.age_range.message}
              </small>
            </div> */}
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Gender*
              </label>
              <Select
                defaultValue={getValues("gender")}
                placeholder="Select your gender"
                className="w-full"
                optionFilterProp="label"
                status={errors.gender ? "error" : ""}
                {...register("gender", {
                  required: "Gender is required",
                })}
                onChange={(value) => {
                  setValue("gender", value);
                  trigger("gender");
                }}
                options={[
                  { value: "male", label: "male" },
                  { value: "female", label: "Female" },
                ]}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.gender && errors.gender.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Marital Status*
              </label>
              <Select
                defaultValue={getValues("maritalStatus")}
                placeholder="Select your marital status"
                className="w-full"
                optionFilterProp="label"
                status={errors.maritalStatus ? "error" : ""}
                {...register("maritalStatus", {
                  required: "marital status is required",
                })}
                onChange={(value) => {
                  setValue("maritalStatus", value);
                  trigger("maritalStatus");
                }}
                options={[
                  { value: "single", label: "Single" },
                  { value: "married", label: "Married" },
                  { value: "divorce", label: "Divorce" },
                ]}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.maritalStatus && errors.maritalStatus.message}
              </small>
            </div>
            
            {!updateForm && (
              <div className="mb-2">
                <label htmlFor="bvn" className="font-[400] text-[14px]">
                  BVN*
                </label>
                <Controller
                  name="bvn"
                  control={control}
                  rules={{
                    required: "This field is required",
                    pattern: {
                      value: /^\d{11}$/,
                      message: "BVN Number must be 11 digits long",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Enter your BVN"
                      defaultValue={getValues("bvn")}
                      className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                        errors.bvn ? "error" : ""
                      }`}
                      status={errors.bvn ? "error" : ""}
                      onChange={(value) => {
                        field.onChange(value);
                        trigger("bvn");
                      }}
                      onKeyPress={handleKeyPress}
                    />
                  )}
                />
                <small style={{ fontSize: "13px" }} className="text-red-500">
                  {errors.bvn && errors.bvn.message}
                </small>
              </div>
            )}

            <div className="mt-7 mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                loading={isPending}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default PersonalInformation;
