import React from "react";
import ButtonConfig from "../../../common/ButtonConfig";
import { Button } from "antd";
import { DashboardCustomizeIcon } from "../../../../utils/svg_icons";
import { useModal } from "../../../../hooks/zustand_hooks/useModal";

const DashboardCreateButton = () => {
  const { openModal } = useModal();

  const handleAction = () => {
    openModal({ title: "What would you like to do?" });
  };

  return (
    <>
    <div className="fixed bottom-0 right-0 z-10">
      <ButtonConfig>
        <Button
          size="large"
          icon={<DashboardCustomizeIcon size={30} />}
          onClick={handleAction}
          className="py-8 !px-8 absolute bottom-8 right-8 rounded-full"
        ></Button>
      </ButtonConfig>
    </div>
    </>
  );
};

export default DashboardCreateButton;
