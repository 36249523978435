import React, { useState } from "react";
import CustomButton from "../../../../common/CustomButton";
import { useDrawer } from "../../../../../hooks/zustand_hooks/useDrawer";

const loanType = [
  {
    type: "custom",
    name: "Custom Credit Application",
    label: "Send a custom loan application to an individual customer",
  },
  {
    type: "general",
    name: "General Loan Application",
    label: "Create a general credit application and share on any platform",
  },
];

const LoanApplicationType = () => {
  const [typeName, setTypeName] = useState("");

  //================== use drawer hook ==================
  const { openDrawer } = useDrawer();

  const handleSelectType = (type) => {
    setTypeName(type);
  };

  const handleContinue=()=>{
    openDrawer("APPLICATION_LABEL_FORM", null,"LOAN_APPLICATION_TYPE");
  }

  return (
    <>
      {/* <div className="h-32 flex items-center justify-center">
      <h3 className="text-lg text-gray-600"><i>Coming Soon!</i></h3>
    </div> */}
      <main className="w-full md:max-w-md max-w-lg mx-auto flex flex-col md:gap-y-20 gap-y-10">
        <div>
          <h4 className="font-[600] text-[18px] mb-5">Select Loan Application Type</h4>
          <div className="flex flex-col gap-y-5">
            {loanType?.map((item, index) => {
              return (
                <div
                  key={item?.type + "__loan"}
                  className="border border-[#D8DAE5] rounded flex justify-between gap-4 items-center py-[14px] px-[16px] cursor-pointer"
                  onClick={() => handleSelectType(item?.type)}
                >
                  <div>
                    <h4 className="font-[500] text-[16px] text-[#2A2A29]">
                      {item?.name}
                    </h4>
                    <p className="text-[#474D66] font-[400] text-[13px]">
                      {item?.label}
                    </p>
                  </div>
                  <div
                    className={`h-[16px] w-[16px] rounded-full border border-[#D8DAE5] transition-all ${
                      typeName === item?.type ? "bg-[#733534]" : ""
                    }`}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="">
          <CustomButton className={"w-full"} onClick={handleContinue}>Continue</CustomButton>
        </div>
      </main>
    </>
  );
};

export default LoanApplicationType;
