import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../common/form_inputs/Input";
import CustomButton from "../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import PropTypes from "prop-types";
import { useState } from "react";
import { useInitiateLoan } from "../../../../api/mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { Modal, Space, DatePicker } from "antd";
import moment from "moment";

const LetKnowYou = ({ paramID }) => {

  const [response, setResponse] = useState(null);

  const [openModalDialog, setOpenModalDialog] = useState(false);

  const {
    data: { form_values },
    updateCurrentPage,
  } = useCustomerLoanApplication();

  const { mutate, isPending } = useInitiateLoan(paramID);

  const { showMessage, contextHolder } = useCustomMessage();

  const {
    handleSubmit,
    formState: { errors },
    trigger,
    register,
    getValues,
    setValue,
    control,
    watch,
  } = useForm({
    defaultValues: {
      nin: form_values?.nin,
    },
    mode: "onBlur",
    criteriaMode: "all",
  });



  const onSubmit = (data) => {
    const ninNumber = data?.nin;

    const payload = {
      amount: form_values?.loan_amount,
      repayment_period: form_values?.max_repayment_period,
      nin: ninNumber,
    };

    mutate(payload, {
      onError: (error) => {
        const errorMsg = error?.response?.data?.message || error?.message;

        showMessage("error", errorMsg);
      },
      onSuccess: (res) => {
        const responseData = res?.data;
        console.log(res);
        setResponse({
          ...responseData?.data,
          message: responseData?.message,
        });
        setOpenModalDialog(true);
      },
    });

    // updateCurrentPage("NEW_USER_FORM", {
    //   form_values: { ...form_values, nin: response?.nin, userID: response?.id },
    // });
  };

  const onChange = (e) => {
    // if (e.target.value?.length === 11) {
    //   searchNIN(e.target.value);
    // }
    setValue("nin", e.target.value);
    trigger("nin");
  };

  const ninNumber = watch("nin");
  const handleConfirmNIN = () => {
    const payload = {
      amount: form_values?.loan_amount,
      repayment_period: form_values?.max_repayment_period,
      nin: ninNumber,
    };

    mutate(payload, {
      onError: (error) => {
        const errorMsg = error?.response?.data?.message || error?.message;

        showMessage("error", errorMsg);
      },
      onSuccess: (res) => {
        const responseData = res?.data;
        setResponse({
          ...responseData?.data,
          message: responseData?.message,
        });
        setOpenModalDialog(true);
      },
    });
  };

  const handleContinue = () => {
    if (response?.require_dob) {
      const payload = {
        amount: form_values?.loan_amount,
        repayment_period: form_values?.max_repayment_period,
        nin: ninNumber,
        dob: moment(getValues("dob")).format("YYYY/MM/DD"),
      };

      //  console.log(payload)

      mutate(payload, {
        onError: (error) => {
          const errMsg = error?.response?.data?.message || error?.message;
          showMessage("error", errMsg);
        },
        onSuccess: (res) => {
          const responseData = res?.data;
          setResponse({
            ...responseData?.data,
            message: responseData?.message,
          });
          // updateCurrentPage("NEW_USER_FORM", {
          //   form_values: { ...form_values, nin: response?.nin, userID: response?.id },
          // });
        },
      });
    } else {
      updateCurrentPage("NEW_USER_FORM", {
        form_values: {
          ...form_values,
          nin: response?.nin,
          userID: response?.id,
        },
      });
    }
  };

  return (
    <>
      <div className="mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Let’s know you!</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center gap-x-5">
              <div className="mb-2">
                <label htmlFor="nin" className="font-[400] text-[14px]">
                  Enter NIN
                </label>
                <Controller
                  name="nin"
                  control={control}
                  rules={{
                    required: "This field is required",
                    pattern: {
                      value: /^\d{11}$/,
                      message: "NIN Number must be 11 digits long",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Space.Compact
                      style={{
                        width: "100%",
                      }}
                    >
                      <Input
                        {...field}
                        name="nin"
                        defaultValue={getValues("nin")}
                        type="number"
                        placeholder="Enter your NIN"
                        size={"large"}
                        className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                          errors.nin ? "error" : ""
                        }`}
                        status={errors.account_number ? "error" : ""}
                        onChange={onChange}
                      />
                      <CustomButton
                        loading={isPending}
                        // onClick={handleConfirmNIN}
                        type={"submit"}
                      >
                        Next
                      </CustomButton>
                    </Space.Compact>
                  )}
                />
                <p style={{ fontSize: "13px" }} className="text-red-500">
                  {errors.nin && errors.nin.message}
                </p>
              </div>
            </div>

            <Modal
              open={openModalDialog}
              onCancel={() => setOpenModalDialog(false)}
              footer={null}
              // centered={true}
            >
              <div className="mt-6">
                {response?.is_retuning_customer || response?.is_pending ? (
                  <p className="font-[300] text-center text-[16px] leading-[24px]">
                    {response?.message}
                  </p>
                ) : (
                  <div className="flex flex-col gap-y-4">
                    <p className="font-[400] text-center text-[14px]">
                      {response?.message}
                    </p>
                    {response?.require_dob && (
                      <div className="mb-2">
                        <label htmlFor="" className="font-[400] text-[14px]">
                          Date of Birth*
                        </label>
                        <DatePicker
                          className={"w-full z"}
                          {...register("dob", {
                            required: "Date of birth is required",
                          })}
                          size="large"
                          status={errors.dob ? "error" : ""}
                          onChange={(date, dateString) => {
                            setValue("dob", dateString);
                            trigger("dob");
                          }}
                        />
                        <small
                          style={{ fontSize: "13px" }}
                          className="text-red-500"
                        >
                          {errors.dob && errors.dob.message}
                        </small>
                      </div>
                    )}
                    <CustomButton
                      onClick={handleContinue}
                      className={"w-full"}
                      loading={isPending}
                      type="submit"
                    >
                      Continue
                    </CustomButton>
                  </div>
                )}
              </div>
            </Modal>
          </form>
        </div>
      </div>
      {contextHolder}
    </>
  );
};

export default LetKnowYou;

LetKnowYou.propTypes = {
  account: PropTypes.any,
};
