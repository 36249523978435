import { Result } from "antd";
import CustomButton from "../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const ApplicationSubmitted = () => {
  const {
    updateCurrentPage,
    data: { response }
  } = useCustomerLoanApplication();

  const handleContinue = () => {
      updateCurrentPage("LOAN_APPLICATION_SUMMARY", null);
    }

  return (
    <>
      <main>
        <Result
          status={"success"}
          title={
            <>
              <p className="font-[500] text-[24px] text-[#2A2A29]">
                {response?.title}
              </p>
              <br />
              <p className="text-[16px] font-[300] leading-[24px]">{response?.desc}</p>
            </>
          }
          extra={
            response?.continue? [
            <CustomButton
              className={"w-full max-w-lg"}
              key={'1'}
              onClick={handleContinue}
            >
              Continue to loan
            </CustomButton>,
          ]:null}
        />
      </main>
    </>
  );
};

export default ApplicationSubmitted;
