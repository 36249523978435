import React, { useState } from "react";
import CustomButton from "../../../../common/CustomButton";
import { useDrawer } from "../../../../../hooks/zustand_hooks/useDrawer";

const optionType = [
  {
    type: "set",
    name: "Set Mandate",
    label: "",
  },
  {
    type: "no",
    name: "I don't want to set Mandate",
    label: "",
  },
];

const NewMandateOption = () => {
  const [typeName, setTypeName] = useState("");

  //================== use drawer hook ==================
  const { openDrawer, updateDrawerTitle, data: { user_data} } = useDrawer();

  const handleSelectType = (type) => {
    setTypeName(type);
  };

  const handleContinue=()=>{
      if(typeName==="set"){
        const mandateUserData = {
            first_name: user_data?.first_name,
            last_name: user_data?.last_name,
            bvn: user_data?.bioData?.bvn,
            phone: user_data?.phone,
            email: user_data?.email,
            address: user_data?.contact?.address,
            auto_debit: user_data?.auto_debit,
        }
        openDrawer("MANDATE_INFO", { values: mandateUserData }, "NEW_MANDATE_OPTION");
        updateDrawerTitle("New Mandate")
    }
    else{
        openDrawer("KYC_COMPLETED", { response: { title: "KYC Completed", goToHome: true }});
        updateDrawerTitle("New Mandate")
    }
  }

  return (
    <>
      {/* <div className="h-32 flex items-center justify-center">
      <h3 className="text-lg text-gray-600"><i>Coming Soon!</i></h3>
    </div> */}
      <main className="w-full md:max-w-md max-w-lg mx-auto flex flex-col md:gap-y-20 gap-y-10">
        <div>
          <div className="flex flex-col gap-y-5">
            {optionType?.map((item, index) => {
              return (
                <div
                  key={item?.type + "__loan"}
                  className="border border-[#D8DAE5] rounded flex justify-between gap-4 items-center py-[14px] px-[16px] cursor-pointer"
                  onClick={() => handleSelectType(item?.type)}
                >
                  <div>
                    <h4 className="font-[500] text-[16px] text-[#2A2A29]">
                      {item?.name}
                    </h4>
                    <p className="text-[#474D66] font-[400] text-[13px]">
                      {item?.label}
                    </p>
                  </div>
                  <div
                    className={`h-[16px] w-[16px] rounded-full border border-[#D8DAE5] transition-all ${
                      typeName === item?.type ? "bg-[#733534]" : ""
                    }`}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="">
          <CustomButton className={"w-full"} onClick={handleContinue}>Next</CustomButton>
        </div>
      </main>
    </>
  );
};

export default NewMandateOption;
