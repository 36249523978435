

import { useState } from 'react';
import { Table } from "../../../../../common/form_inputs/Table";
import moment from "moment";
import { formatNumberOnly } from '../../../../../../utils/formatNumber';
import { decryptData } from '../../../../../../utils/cryptData';



const ViewBankStatement = ({detailData}) => {


  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text, row) => (
          <p className="flex flex-col">
            {decryptData(row?.type)}
          </p>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text, row) => (
          <p className="">NGN {formatNumberOnly(decryptData(row?.amount) ?? 0)}</p>
    
      ),
    },
    {
      title: 'Narration',
      dataIndex: 'narration',
      render: (text, row)=>(
        <>
          {decryptData(row?.narration) ?? "N/A"}
        </>
      )
    },
    
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text, row)=>(
        <>
          {moment(decryptData(row?.loanSetting?.name))?.format() ?? "N/A"}


        </>
      )
    }
  ];


  const dataSource = detailData?.map((data, i) => ({...data, key: data?.id+i}));





  return (
    <>
        <Table
        columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} pagination={false} striped={true}/>
    
    </>
  );
};
export default ViewBankStatement;