import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { VerifiedIcon } from "../../../../../../utils/svg_icons";
import {
  useVerifyData,
  useUpdateKyc,
  useGetHistoryOrStatement,
} from "../../../../../../api/admin_mutation";
import StarLoader from "../../../../../loader/StarLoader";
import useCustomMessage from "../../../../../../hooks/zustand_hooks/useCustomMessage";
import Confirm from "../../../../../common/Confirm";
import { API_URL } from "../../../../../../api/api_urls";
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";

const RenderSection = ({
  title,
  field_names,
  data,
  coming_soon,
  require_verify,
  checkAuthenticity,
  keyName,
}) => {
  const [dataIndex, setDataIndex] = useState(null);
  const [updateState, setUpdateState] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [requestPayload, setRequestPayload] = useState(null);

  const { openDrawer } = useDrawer();

  const { mutate, isPending } = useVerifyData();
  const {
    mutate: mutateHistoryOrStatement,
    isPending: historyOrStatementLoading,
  } = useGetHistoryOrStatement();

  const { mutate: mutateUpdateKyc, isPending: updateKycLoading } = useUpdateKyc(
    data?.id
  );

  const { showMessage, contextHolder } = useCustomMessage();

  const handleGetData = (title) => {
    const key = title?.toLowerCase()?.replace(/ /g, "_");
    setDataIndex(title);
    const path =
      key === "credit_history"
        ? API_URL?.check_credit_bureau
        : key === "bank_statement" && API_URL.account_statement;
    const value =
      key === "credit_history"
        ? { bvn: data?.bvn, loanId: data?.id }
        : key === "bank_statement" && data?.id;

    mutateHistoryOrStatement(
      {
        keyName: key,
        value,
      },
      {
        onError: (error) => {
          const errMsg = error?.response?.data?.message || error?.message;

          showMessage("error", errMsg);
        },
        onSuccess: (resData) => {
          const response = resData?.data?.data || resData?.data;
          console.log(response);

          showMessage(
            response?.status === "failed" ? "error" : "success",
            response?.message
          );

          openDrawer(
            "VERIFY_DATA",
            {
              responseData: response?.data,
              keyName: [keyName],
              account_status: data?.account_status,
              userLoanID: data?.id,
            },
            "KYC_PROCESS"
          );
        },
      }
    );
  };

  // console.log(data, keyName)

  const handleUpdateKyc = (keyName, status) => {
    setUpdateState(status);
    setDataIndex(keyName);
    const payload = {
      key: keyName,
      status: status,
    };

    setOpenConfirm(true);
    setRequestPayload(payload);
  };

  const handleVerifyData = (params) => {
    const { keyName } = params;

    const payloads = {
      meterNumber: {
        disco: data?.disco,
        meter: data?.meter_number,
        loanId: data?.id,
      },
      bvnIdentity: { bvn: data?.bvn, loanId: data?.id },
      ninIdentity: { nin: data?.nin, loanId: data?.id },
    };

    const payload = {
      keyName,
      data: payloads?.[keyName],
    };

    mutate(payload, {
      onError: (error) => {
        const errMsg = error?.response?.data?.message || error?.message;

        showMessage("error", errMsg);
        openDrawer(
          "VERIFY_DATA",
          {
            responseData: {},
            keyName: keyName === "meterNumber" ? ["contact"] : [keyName],
            account_status: data?.account_status,
            userLoanID: data?.id,
          },
          "KYC_PROCESS"
        );
      },
      onSuccess: (resData) => {
        const response = resData?.data;

        console.log(response);

        showMessage(
          response?.status === "failed" ? "error" : "success",
          response?.message
        );
        openDrawer(
          "VERIFY_DATA",
          {
            responseData: response?.data,
            keyName: keyName === "meterNumber" ? ["contact"] : [keyName],
            account_status: data?.account_status,
            userLoanID: data?.id,
          },
          "KYC_PROCESS"
        );
      },
    });
  };

  const handleConfirmUpdateKyc = () => {
    if (requestPayload) {
      mutateUpdateKyc(requestPayload, {
        onError: (error) => {
          const errMsg = error?.response?.data?.message || error?.message;

          showMessage("error", errMsg);
        },
        onSuccess: (data) => {
          const response = data?.data;

          showMessage(
            response?.status === "failed" ? "error" : "success",
            response?.message
          );
        },
        onSettled: () => {
          setOpenConfirm(false);
        },
      });
    }
  };

  console.log(data?.account_status);

  return (
    <>
      <main>
        <section className="w-full md:max-w-md mx-auto">
          <h3 className="text-center font-[400] text-[16px] text-[#4F4F4F] flex justify-center gap-x-10">
            <span className="flex">
              {title}
              {data?.account_status?.[keyName] === "valid" ? (
                <small className="text-red-500">
                  <VerifiedIcon />
                </small>
              ): data?.account_status?.[keyName] === "invalid" && (
                <small className="text-red-500 ms-2 text-lg">
                  X
                </small>
              )}
            </span>
            {coming_soon && (
              <small className="text-red-500 ">Coming soon!</small>
            )}
          </h3>
        </section>
        <hr className="h-2" />
        <section className="my-5 w-full md:max-w-md mx-auto">
          <div className="flex flex-col gap-y-3">
            {field_names?.map((item, index) => (
              <div key={item?.value + index}>
                {item?.link ? (
                  <div className="flex justify-center">
                    <Button
                      type="text"
                      className={`cursor-pointer underline ${data?.account_status?.[keyName]==="valid"? "text-green-500":data?.account_status?.[keyName]==="invalid"? "text-red-500": "#733534"} font-[300] text-[14px]`}
                      onClick={() =>
                        data?.account_status?.[keyName] === "valid"
                          ? {}
                          : data?.account_status?.[keyName] === "invalid"
                          ? {}
                          : handleGetData(title)
                      }
                      loading={dataIndex === title && historyOrStatementLoading}
                    >
                      {data?.account_status?.[keyName] === "valid"
                        ? "Verified"
                        : data?.account_status?.[keyName] === "invalid"
                        ? "Rejected"
                        : item?.label}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <div className="flex justify-between md:flex-nowrap flex-wrap">
                      <h4 className="font-[400] text-[14px]">{item?.label}:</h4>
                      <div className="flex gap-x-5 items-center">
                        <p className="font-[300]">
                          {data?.[item?.value] ?? "N/A"}
                        </p>
                        {item?.requireVerify && data?.[item?.value] && (
                          <div className="flex items-center">
                            {item?.requireVerify &&
                              (data?.account_status?.[item?.keyName] ===
                              "valid" ? (
                                <Button
                                  type={"text"}
                                  className="cursor-pointer underline text-green-500 font-[300] text-[14px]"
                                >
                                  Verified
                                </Button>
                              ) : (
                                <Button
                                  type={"text"}
                                  onClick={() => {
                                    setDataIndex(index);
                                    handleVerifyData({
                                      keyName: item?.keyName,
                                    });
                                  }}
                                  loading={isPending && dataIndex === index}
                                  className="cursor-pointer underline text-[#733534] font-[300] text-[14px]"
                                >
                                  {item?.action_name}
                                </Button>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {/* {checkAuthenticity && (
              <div className="text-center">
                <Button
                  type={"text"}
                  onClick={() => {
                    const payload = {
                      bvn: data?.bvn,
                      nin: data?.nin,
                      d_o_b: data?.dob,
                    };
                    handleVerifyData({ payload });
                  }}
                  loading={isPending}
                  className="cursor-pointer underline text-[#733534] font-[300] text-[14px]"
                >
                  Check Authenticity
                </Button>
              </div>
            )} */}
          </div>
          {require_verify && (
            <div className="flex justify-center gap-x-8 mt-5">
              <Button
                type="solid"
                className="bg-[#733534] text-white hover:bg-[#733534] hover:text-white disabled:bg-[#8E6565]"
                disabled={data?.account_status?.[keyName] === "invalid"}
                onClick={() =>
                  data?.account_status?.[keyName] === "invalid"
                    ? null
                    : handleUpdateKyc(keyName, "invalid")
                }
              >
                {data?.account_status?.[keyName] === "invalid"
                  ? "Rejected"
                  : "Reject"}
              </Button>
              <Button
                type="solid"
                className={`bg-green-500 text-white hover:bg-green-500 hover:text-white disabled:bg-[#6E8769]`}
                disabled={data?.account_status?.[keyName] === "valid"}
                onClick={() =>
                  data?.account_status?.[keyName] === "valid"
                    ? null
                    : handleUpdateKyc(keyName, "valid")
                }
              >
                {data?.account_status?.[keyName] === "valid"
                  ? "Verified"
                  : "Verify"}
              </Button>
            </div>
          )}
        </section>
      </main>

      <Confirm
        openConfirm={openConfirm}
        confirmLoading={updateKycLoading}
        setOpenConfirm={setOpenConfirm}
        message={"Are you sure to confirm this action?"}
        handleConfirm={handleConfirmUpdateKyc}
      />

      {contextHolder}
    </>
  );
};

export default RenderSection;

RenderSection.propTypes = {
  title: PropTypes.string,
  field_names: PropTypes.array,
  data: PropTypes.object,
  coming_soon: PropTypes.bool,
  require_verify: PropTypes.bool,
};
