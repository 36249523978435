import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoanApplicationLayout from "../../../components/layouts/loan_application_layout/LoanApplicationLayout";
import LoanRequest from "../../../components/specific/customer_loan_application/custom_form/LoanRequest";
import LetKnowYou from "../../../components/specific/customer_loan_application/custom_form/LetKnowYou";
import CompleteKyc from "../../../components/specific/customer_loan_application/custom_form/CompleteKyc";
import VerificationOtp from "../../../components/specific/customer_loan_application/custom_form/VerificationOtp";
import ShareBankStatement from "../../../components/specific/customer_loan_application/custom_form/ShareBankStatement";
import KycSubmitted from "../../../components/specific/customer_loan_application/custom_form/ApplicationSubmitted";
import KycDetailSummary from "../../../components/specific/customer_loan_application/custom_form/KycDetailSummary";
import LoanApplicationSummary from "../../../components/specific/customer_loan_application/custom_form/LoanApplicationSummary";
import NewUserForm from "../../../components/specific/customer_loan_application/custom_form/NewUserForm";
import LoanApplicationLinkCreated from "../../../components/specific/customer_loan_application/custom_form/LoanApplicationLinkCreated";
import DisbursementAccountForm from "../../../components/specific/customer_loan_application/custom_form/DisbursementAccountForm";
import GuarantorInfo from "../../../components/specific/customer_loan_application/custom_form/GuarantorInfo";
import { useCustomerLoanApplication } from "../../../hooks/zustand_hooks/useCustomerLoanApplication";
import {
  useGetUserLoanSetting,
  useGetLoanDetails,
} from "../../../api/getHooks";
import StarLoader from "../../../components/loader/StarLoader";
import UpdateRejectedDetail from "../../../components/specific/customer_loan_application/custom_form/UpdateRejectedDetail";

const page_section = {
  LOAN_REQUEST: "Complete your loan form here",
  LET_KNOW_YOU:
    "Loans are insurance, you are always covered as long as you pay back.",
  COMPLETE_KYC:
    "Loans are insurance, you are always covered as long as you pay back.",
};

const CustomApplication = ({ action }) => {
  const param = useParams();
  const [firstLoad, setFirstLoad] = useState(true); // State to track first load

  const paramID = param.ID;

  const { data } = useGetUserLoanSetting(paramID);
  const { data: getLoanDetail, isLoading: detailLoading } =
    useGetLoanDetails(paramID);

  const loan_details = getLoanDetail?.data?.data;

  const loanSetting = data?.data?.data;

  const {
    currentPage,
    title,
    updateData,
    updateCurrentPage,
    changeStep,
    updateTitle,
  } = useCustomerLoanApplication();

  useEffect(() => {
    if (loan_details && loan_details?.status === "declined") {
      updateTitle("Update Rejected Details");
      updateCurrentPage("APPLICATION_REJECTED", {});
    }
  }, [loan_details, loan_details?.status, updateCurrentPage, updateTitle]);

  useEffect(() => {
    if (loanSetting) {
      updateData({ loan_setting: { ...loanSetting } });
    }
  }, [loanSetting, updateData]);


  useEffect(() => {
    if (loan_details) {
      updateData({ userDetail: { ...loan_details } });

      if (firstLoad) {
        // Set firstLoad to false after initial data fetch
        setFirstLoad(false);

        if (action === "continue") {
          if (loan_details?.is_retuning_customer) {
            updateCurrentPage("LOAN_APPLICATION_SUMMARY", {
              userDetail: { ...loan_details },
            });
          }  else {
            if (loan_details?.isPending && !loan_details?.first_name) {
              updateCurrentPage("LOAN_APPLICATION_SUMMARY", {
                userDetail: { ...loan_details },
              });
            } else if (loan_details?.kyc_stepper <= 3) {
              updateCurrentPage("COMPLETE_KYC", {
                userDetail: { ...loan_details },
              });
              changeStep(loan_details?.kyc_stepper);
            } else if (loan_details?.kyc_stepper === 4) {
              updateCurrentPage("KYC_DETAIL_SUMMARY", {});
            } else if (
              loan_details?.kyc_stepper === 5 &&
              loan_details?.loanSetting?.account_statment
            ) {
              updateCurrentPage("SHARE_BANK_STATEMENT", {});
            } else if (
              loan_details?.account_statement &&
              loan_details?.loanSetting?.account_statment
            ) {
              updateCurrentPage("SHARE_BANK_STATEMENT", {});
            } else {
              if (!loan_details?.account_info?.account_number) {
                updateCurrentPage("LOAN_APPLICATION_SUMMARY", {});
              } else if (
                loan_details?.loanSetting?.guarantor &&
                !loan_details?.guarantor_info?.first_name
              ) {
                updateCurrentPage("GUARANTOR_DETAIL", {});
              } else {
                updateCurrentPage("APPLICATION_COMPLETED", {
                  response: {
                    title: "Loan application submitted",
                    desc: "Your application will be reviewed",
                    continue: false,
                  },
                });
              }
            }
          }
        }
      }
    }
  }, [loan_details]);

  const showLoader = detailLoading;


  return (
    <>
      {showLoader ? (
        <div className="flex justify-center items-center h-screen">
          <StarLoader size={50} />
        </div>
      ) : (
        <LoanApplicationLayout
          description={page_section?.[currentPage]}
          title={title}
          customer_name={loan_details?.first_name}
        >
          {(loan_details?.status === "declined" || loan_details?.status === "rejected") ? (
            <UpdateRejectedDetail paramID={paramID} />
          ) : currentPage === "LOAN_REQUEST" ? (
            <LoanRequest />
          ) : currentPage === "LET_KNOW_YOU" ? (
            <LetKnowYou paramID={paramID} />
          ) : currentPage === "NEW_USER_FORM" ? (
            <NewUserForm paramID={paramID} />
          ) : currentPage === "APPLICATION_LINK_CREATED" ? (
            <LoanApplicationLinkCreated />
          ) : currentPage === "COMPLETE_KYC" ? (
            <CompleteKyc paramID={paramID} />
          ) : currentPage === "KYC_DETAIL_SUMMARY" ? (
            <KycDetailSummary paramID={paramID} />
          ) : currentPage === "VERIFY_OTP" ? (
            <VerificationOtp />
          ) : currentPage === "SHARE_BANK_STATEMENT" ? (
            <ShareBankStatement paramID={paramID} />
          ) : currentPage === "APPLICATION_COMPLETED" ? (
            <KycSubmitted />
          ) : currentPage === "LOAN_APPLICATION_SUMMARY" ? (
            <LoanApplicationSummary />
          ) : currentPage === "DISBURSEMENT_ACCOUNT" ? (
            <DisbursementAccountForm />
          ) : currentPage === "GUARANTOR_DETAIL" ? (
            <GuarantorInfo />
          ) : currentPage === "APPLICATION_REJECTED" ? (
            <UpdateRejectedDetail paramID={paramID} />
          ) : null}
        </LoanApplicationLayout>
      )}
    </>
  );
};

export default CustomApplication;
