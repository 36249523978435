

import { useState } from 'react';
import { Table } from "../../form_inputs/Table";
import moment from "moment";
import { formatNumberOnly } from '../../../../utils/formatNumber';
import { decryptData } from '../../../../utils/cryptData';
import { copyToClipboard } from '../../../../utils/copyToClipboard';
import { IoCopy, IoCopyOutline, IoEyeOutline } from "react-icons/io5";



const LoanApplicationTable = ({rows, handleOpen}) => {

  const [copy, setCopy] = useState({
    icon: IoCopyOutline,
    state: false,
    id: null,
    text: "Copy url",
  });


  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const handleCopy = (val, index) => {
    copyToClipboard(`${baseUrl}/user/loan_application/continue/${val?.loan_id}`)?.then((res) => {
      if(res.status) setCopy({ icon: IoCopy, state: true, text: "Copied", id: val?.id });
    })
};


  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      render: (text, row) => (
          <p className="flex flex-col">{(decryptData(row?.first_name) + " "+decryptData(row?.last_name)  )?? ""}
           <small className="text-gray-400" style={{ fontSize: '12px' }}>
            {moment(decryptData(row?.createdAt)).format('h:mm:ss a, D MMMM YYYY')}
          </small>
          </p>
      ),
    },
    {
      title: 'Credit Amount',
      dataIndex: 'credit_amount',
      render: (text, row) => (
          <p className="">NGN {formatNumberOnly(decryptData(row?.amount) ?? 0)}</p>
    
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      render: (text, row)=>(
        <>
          {decryptData(row?.phone) ?? "N/A"}
        </>
      )
    },
    
    {
      title: 'Loan Label',
      dataIndex: 'loan_label',
      render: (text, row)=>(
        <>
          {decryptData(row?.loanSetting?.name) ?? "N/A"}


        </>
      )
    },
    {
      title: 'Copy Link',
      dataIndex: 'loan_id',
      render: (text, row)=>(
        <span
        className="flex gap-x-1 text-[#733534] cursor-pointer"
        onClick={()=>handleCopy(row)}
      >
        {
          copy?.id===row?.id && copy?.state ? (
            <IoCopy color="#733534" size={"3vh"} />
          ): <IoCopyOutline color="#733534" size={"3vh"} />
        }
        {copy?.id===row?.id && copy?.state ? "Copied" : "Copy url"}
      </span>
      )
    },
    {
      title: 'Application',
      dataIndex: 'status',
      render: (text, row) => (
        <div className={`capitalize flex gap-x-1 items-center max-w-full ${decryptData(row?.status)==="pending"? "bg-yellow-100 text-yellow-900" : (decryptData(row?.status)==="approved" || decryptData(row?.status)==="revalidate")? "bg-green-100 text-green-900" : "bg-red-100 text-red-500" } rounded-full px-2 text-center uppercase font-[500]`}>
            <p className={`h-2 w-2 rounded-full ${decryptData(row?.status)==="pending"? "bg-yellow-700" : (decryptData(row?.status)==="approved" || decryptData(row?.status)==="revalidate")? "bg-green-700": "bg-red-700"}`}></p>
            <i>{decryptData(row?.status)}</i></div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, row)=>(
        <>
          <small className='cursor-pointer' 
          onClick={()=>handleOpen(row)}
          >
            <IoEyeOutline size={"18px"}/>
          </small>
        </>
      )
    },
  ];


  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}));





  return (
    <>
        <Table
        columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} pagination={false} striped={true}/>
    
    </>
  );
};
export default LoanApplicationTable;