import React from "react";
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import CustomButton from "../../../../../common/CustomButton";
import { useCreateLoanSetting } from "../../../../../../api/admin_mutation";
import useCustomMessage from "../../../../../../hooks/zustand_hooks/useCustomMessage";

const summaryData = [
  { value: "application_label", label: "Application Label" },
  { value: "interest", label: "Interest" },
  { value: "maximum_amount", label: "Maximum Credit Amount" },
  { value: "age_range", label: "Minimum Age" },
  { value: "repayment_period", label: "Maximum Repayment period" },
  { value: "repayment_type", label: "Duration Type" },
  { value: "guarantor", label: "Request Guarantor" },
  { value: "description", label: "Additional Information" },
  { value: "states", label: "Applicable States" },
  // { value: "debit_guarantor", label: "Debit Guarantor in case of default" },
];

const ApplicationSummary = () => {
  const {
    openDrawer,
    data: { values },
  } = useDrawer();


  const { showMessage, contextHolder } = useCustomMessage();


  const { mutate, isPending } = useCreateLoanSetting();

  const handleOnComplete = () => {
    //make request to server
    const payload = {
      "name": values?.application_label,
      "interest": values?.interest,
      "minimum_amount": values?.minimum_amount,
      "maximum_amount": values?.maximum_amount,
      "age_range": values?.age_range,
      "repayment_period": values?.repayment_period,
      "repayment_type": values?.repayment_type,
      "guarantor": values?.guarantor,
      "debit_guarantor": values?.debit_guarantor,
      "work_id": values?.work_id,
      "verify_nin": values?.verify_nin,
      "description": values?.description,
      "account_statment": values?.account_statment,
      states: values?.states,
  }



  mutate(payload, {
    onSuccess: (res)=>{
      const responseData = res?.data?.data

      openDrawer("CREATE_SUCCESSFULL", { response_message: "Loan application link created", loan_application: true, loanSettingID: responseData?.loan_setting_id });
    },
    onError: (error)=>{
      const errorMsg = error?.response?.data?.message || error?.message;
      showMessage("error", errorMsg);
    }
  })
  };

  return (
    <>
      <main className="w-full max-w-lg mx-auto flex items-center justify-center mt-4">
        <div className="flex flex-col gap-y-10 w-full">
          <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm flex flex-col gap-y-8">
            <h4 className="font-[600] text-[18px]">Summary</h4>
            <div className="flex flex-col gap-y-3">
              {summaryData?.map((item, index) => (
                  values?.[item?.value] &&(
                <div key={item?.value + index} className="flex justify-between">
                  
                  <h3 className="font-[400] text-[14px]">{item?.label}:</h3>
                  <p className="font-[275] text-[14px]">
                    {typeof values?.[item?.value] === "boolean"
                      ? values?.[item?.value]
                        ? "Yes"
                        : "No"
                      : item?.value === "full_name"
                      ? `${values["first_name"]} ${values["last_name"]}`
                      : typeof values?.[item?.value]==="object"? values?.[item?.value]?.join(", ") : values?.[item?.value]}
                    {item?.value === "interest" && "%"}
                  </p>
                </div>
                  )
              ))}
            </div>
          </div>
          <div className="mt-6 flex justify-center">
              <CustomButton
                className={"w-full"}
                onClick={handleOnComplete}
                loading={isPending}
              >
                Complete
              </CustomButton>
            </div>
        </div>
      </main>
      {contextHolder}
    </>
  );
};

export default ApplicationSummary;