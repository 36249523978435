import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Input } from "../../../../common/form_inputs/Input";
import CustomButton from "../../../../common/CustomButton";
import { Select } from "../../../../common/form_inputs/Select";
import { useCustomerLoanApplication } from "../../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import { Checkbox } from "../../../../common/form_inputs/Checkbox";
import { useGetDiscos } from "../../../../../api/getHooks";







const AddressInformation = ({makeRequest, isPending}) => {


const {
    changeStep,
    updateData,
    data: { form_values, userDetail }
  } = useCustomerLoanApplication();


  const {data, isLoading} = useGetDiscos()

  const getDisco = data?.data;


  const excludeList = ["STARTIMES", "MTN", "AIRTEL", "GLO", "9MOBILE", "DSTV", "GOTV"]


  const filterNames=(input, excludeList)=>{
    if(input){
      return Object.keys(input)
        .filter(name => !excludeList.includes(name)); // Excludespecific names
    }
  }

 

  const discoData = useMemo(()=>{
    const formatDisco = filterNames(getDisco, excludeList)
    return formatDisco?.map((disco)=>{
      return{
        label: disco,
        value: disco
    }
    })
}, [getDisco])




    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        trigger,
        watch,
        getValues,
      } = useForm({
        defaultValues: {
          city: userDetail?.contact?.city,
          address: userDetail?.contact?.address,
          state: userDetail?.contact?.state,
          meter_number: userDetail?.contact?.meter_number,
          disco: userDetail?.contact?.disco
        }
      });


      const onSubmit = async(data) => {

        const payload = {
          contact: {
              city: data?.city,
              address: data?.address,
              // land_mark: data?.land_mark,
              meter_number: data?.meter_number,
              state: data?.state,
              disco: data?.disco
              //the electricity reciept will be here if data?.electricity_unavailable is true
          }
        };

        
    
        await makeRequest(payload);

        updateData({ form_values: { ...form_values, ...data }})
      };



      const onChangeValue = (e, fieldName) => {
        const value = e.target.value;
    
        setValue(fieldName, value);
        trigger(fieldName);
      };



      const stateOptions = userDetail?.loanSetting?.states?.map(item=>({
        label: item,
        value: item
      })) || [];

  return (
    <>
      <main className="flex flex-col gap-y-5 my-5">
        <h3 className="font-[500] text-[16px] text-center">Address Information</h3>
        
        <div>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label htmlFor="address" className="font-[400] text-[14px]">
                Home Address
              </label>
              <Input
                name="address"
                defaultValue={getValues("address")}
                placeholder="e.g 37, Lemon street"
                size={"large"}
                className={`text-[14px] ${errors.address ? "error" : ""}`}
                status={errors.address ? "error" : ""}
                {...register("address", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "address")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.address && errors.address.message}
              </small>
            </div>
            {/* <div className="mb-2">
              <label htmlFor="land_mark" className="font-[400] text-[14px]">
                Landmark
              </label>
              <Input
                name="land_mark"
                defaultValue={getValues("land_mark")}
                placeholder="Closest bus stop"
                size={"large"}
                className={`text-[14px] ${errors.land_mark ? "error" : ""}`}
                status={errors.land_mark ? "error" : ""}
                {...register("land_mark", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "land_mark")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.land_mark && errors.land_mark.message}
              </small>
            </div> */}
            <div className="mb-2">
              <label htmlFor="city" className="font-[400] text-[14px]">
                City
              </label>
              <Input
                name="city"
                defaultValue={getValues("city")}
                placeholder="e.g Ikeja"
                size={"large"}
                className={`text-[14px] ${errors.city ? "error" : ""}`}
                status={errors.city ? "error" : ""}
                {...register("city", {
                  required: false,
                 
                })}
                onChange={(e) => onChangeValue(e, "city")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.city && errors.city.message}
              </small>
            </div>
          
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                State
              </label>
              <Select
              defaultValue={getValues("state")}
              placeholder="Select state"
              className="w-full"
              optionFilterProp="label"
              status={errors.state ? "error" : ""}
              
              {...register("state", {
                required: "state is required",
              })}
              onChange={(value)=>{
                setValue("state", value)
                trigger("state")
              }}
              options={stateOptions}
            />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.state && errors.state.message}
              </small>
            </div>
            {
              watch("electricity_unavailable") ? (
              <div className="mb-2">
                <label htmlFor="meter_number" className="font-[400] text-[14px]">
                  Upload your house electricity bill
                </label>
                <input type="file" id="upload_photo" accept=".jpg, .png, .jpeg" />
              </div>
              ): (
                <>
                  <div className="mb-2">
                    <label htmlFor="meter_number" className="font-[400] text-[14px]">
                      Electricity Meter Number
                    </label>
                    <Input
                      name="meter_number"
                      defaultValue={getValues("meter_number")}
                      placeholder="Enter meter number here"
                      size={"large"}
                      className={`text-[14px] ${errors.meter_number ? "error" : ""}`}
                      status={errors.meter_number ? "error" : ""}
                      {...register("meter_number", {
                        required: watch("electricity_unavailable") ? false : "This field is required",
                      })}
                      onChange={(e) => onChangeValue(e, "meter_number")}
                    />
                    <small style={{ fontSize: "13px" }} className="text-red-500">
                      {errors.meter_number && errors.meter_number.message}
                    </small>
                  </div>
                  <div className="mb-2">
                <label htmlFor="disco" className="font-[400] text-[14px]">
                  Disco
                </label>

                <Select
                  name="disco"
                  defaultValue={getValues("disco")}
                  placeholder="Select Disco"
                  optionFilterProp = "label"
                  size={"large"}
                  loading={isLoading}
                  options={discoData}
                  className={`text-[14px] w-full ${errors.disco ? "error" : ""}`}
                  status={errors.disco ? "error" : ""}
                  {...register("disco", {
                    required: watch("electricity_unavailable") ? false : "This field is required",
                  })}
                  onChange={(value)=>{
                    setValue("disco", value)
                    trigger("disco")
                  }}
                  
                />
                <small style={{ fontSize: "13px" }} className="text-red-500">
                  {errors.disco && errors.disco.message}
                </small>
              </div>
                </>
              )
            }
            <div className="mb-2">
              <Checkbox
                onChange={(e) =>
                  setValue("electricity_unavailable", e.target.checked)
                }
                checked={getValues("electricity_unavailable")}
                className={"capitalize"}
              >
                I don't have Electricity Number
              </Checkbox>
            </div>


            <div className="mt-7 mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                loading={isPending}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default AddressInformation;
