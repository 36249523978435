import React, { useState } from "react";
import CustomButton from "../../../common/CustomButton";
import { useCreateSubscription } from "../../../../api/admin_mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { Modal } from "antd";
import { RiErrorWarningLine } from "react-icons/ri";

const PlanCard = ({ plan, companyId, active, isAdmin }) => {
  const { showMessage, contextHolder: msgContext } = useCustomMessage();

  const [modal, contextHolder] = Modal.useModal();

  const [plan_id, setPlan_id] = useState("");

  const { openDrawer, updateDrawerTitle } = useDrawer();

  const createSubscription = useCreateSubscription();


  const handleSubscription = (planId) => {
    setPlan_id(planId);
    const json = {
      planId,
      companyId,
      status: true,
    };

    createSubscription.mutate(json, {
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
      onSuccess: (data) => {
        const res = data?.data;

        showMessage("success", res?.message);
      },
    });
  }

  const subscribe = (planId) => {
    modal.confirm({
      title: 'Confirm',
      icon: <RiErrorWarningLine size={30}/>,
      content: 'Are you sure to subscribe to this plan?',
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: ()=> handleSubscription(planId)
    });
    //call subscribe api
    
  };


  const handleEditPlan=(plan)=>{
    openDrawer("UPDATE_PLAN", {plan});
    updateDrawerTitle("Update Plan");
  }

  return (
    <>
      <main className="card border rounded-lg flex flex-col justify-between">
        <div className="flex flex-col gap-y-5">
          <div
            className={`h-[30vh] rounded-lg bg-cover ${
              plan?.image === "" && "bg-gray-400"
            } bg-no-repeat bg-center flex justify-center items-center`}
            style={{
              backgroundImage: `url(${plan?.image})`,
            }}
          >
            <div className="h-[15vh] w-[15vh] flex items-center justify-center font-bold rounded-full bg-white text-dark">
              {plan?.name ?? ""}
            </div>
          </div>
          <div className="px-3 pb-3 flex flex-col gap-y-3">
            <div className="flex flex-col gap-y-1">
              <p className="font-[500] text-[14px]">
                Mandate Number: <span>{plan?.number_of_mandate}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Business Mandate:{" "}
                <span>{plan?.number_of_business_mandate}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Number of Admin: <span>{plan?.number_of_admin}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Number of Debit: <span>{plan?.number_of_debit}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Number of BVN: <span>{plan?.number_of_bvn}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Number of NIN: <span>{plan?.number_of_nin}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Number of Address: <span>{plan?.number_of_address}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Number of Credit: <span>{plan?.number_of_credit}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Number of Loan: <span>{plan?.number_of_loan}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Number of Links: <span>{plan?.number_of_links}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Transaction Fee: <span>{plan?.transaction_fee}%</span>
              </p>
              <p className="font-[500] text-[14px]">
                Debit Fee: <span>NGN {plan?.debit_fee}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Check balance: <span>{plan?.check_balance}</span>
              </p>
              <p className="font-[500] text-[14px]">
                Plan Type: <span>{plan?.plan_type ?? ""}</span>
              </p>
            </div>
            <div>
              {plan?.description && (
                <p className="font-[500] text-[14px]">
                  Description:{" "}
                  <small className="text-justify">{plan?.description}</small>
                </p>
              )}
            </div>

          </div>
        </div>
        <div className="m-3">
              <CustomButton
                className={"w-full"}
                onClick={() => isAdmin? handleEditPlan(plan) : subscribe(plan?.id)}
                loading={createSubscription?.isPending && plan?.id === plan_id}
                disabled={active}
              >
                {
                  isAdmin? "Edit Plan" : (
                    active? "Active" : "Subscribe"
                  )
                }
              </CustomButton>
            </div>
      </main>

      {contextHolder}
      {msgContext}
    </>
  );
};

export default PlanCard;
