import { Result } from "antd";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const LoanApplicationCreated = () => {
  const {
    data: { new_user }
  } = useCustomerLoanApplication();


  return (
    <>
      <main>
        <Result
          status={"success"}
          title={
            <>
              <p className="font-[500] text-[24px] text-[#2A2A29]">
              Loan application Link Created
              </p>
              <br />
              <p className="font-[300] text-[16px]">Continue loan application with the link sent to <span className="text-[#733534] font-[500]">{new_user?.email}</span></p>
            </>
          }
          extra={null}
        />
      </main>
    </>
  );
};

export default LoanApplicationCreated;
