import { formatNumberOnly } from "../../../../utils/formatNumber";
import { ArrowIcon } from "../../../../utils/svg_icons";
import { CiEdit } from "react-icons/ci";
import CustomButton from "../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const LoanApplicationSummary = () => {
  const {
    data: { form_values, userDetail },
    updateCurrentPage,
    updateTitle,
    updateData,
  } = useCustomerLoanApplication();

  const loan_interest = parseFloat(userDetail?.loanSetting?.interest);
  const amount = parseFloat(userDetail?.amount);
  const repayment_period = parseFloat(userDetail?.repayment_period);


    const repayment_amount = ((loan_interest/100 * amount) * repayment_period) + amount

  const handleEdit = () => {
    updateData({
      form_values: {
        ...form_values,
        loan_amount: userDetail?.amount,
        max_repayment_period: userDetail?.repayment_period
      },
    });
    updateCurrentPage("LOAN_REQUEST", { edit_loan_info: true });
  };

  const handleContinue = () => {
    if(userDetail?.loanSetting?.account_statement){
      updateCurrentPage("SHARE_BANK_STATEMENT");
      updateTitle("Share Bank Statement");
    }else{
      updateCurrentPage("DISBURSEMENT_ACCOUNT");
      updateTitle("Loan Disbursement");
    }
  };

  return (
    <>
      <main className="w-full mx-auto px-4">
        <h3 className="font-[500] text-[16px]">Loan Summary</h3>
        <div className="my-5 flex gap-x-8 flex-wrap items-end">
          <div className="flex flex-wrap gap-3 items-center">
            <div className="h-32 w-48 p-4 bg-[#6B689C4F] rounded flex flex-col gap-y-3">
              <p className="font-[275] text-[14px] leading-[18px]">
                Loan Amount
              </p>
              <p>NGN {formatNumberOnly(userDetail?.amount)}</p>
              <p className="font-[275] text-[14px] leading-[18px]">
                Salary Advance
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-[275] text-[14px] leading-[18px]">
                {userDetail?.loanSetting?.interest}% monthly
              </p>
              <ArrowIcon width={"70"} height={"20"} />
            </div>
            <div className="h-32 w-48 p-4 bg-[#6B689C4F] rounded flex flex-col gap-y-3">
              <p className="font-[275] text-[14px] leading-[18px]">Repayment</p>
              <p>NGN {formatNumberOnly(repayment_amount) || ""}</p>
              <p className="font-[275] text-[14px] leading-[18px]">
                {userDetail?.repayment_period} {userDetail?.repayment_type}
              </p>
            </div>
          </div>
          <div>
            <p
              className="text-[#901414] cursor-pointer flex items-center"
              onClick={handleEdit}
            >
              Edit
              <CiEdit className={"text-[#901414]"} size={20} />
            </p>
          </div>
        </div>
        <div className="mt-6">
          <CustomButton className={"w-full md:w-3/5"} onClick={handleContinue}>
            Continue
          </CustomButton>
        </div>
      </main>
    </>
  );
};

export default LoanApplicationSummary;
