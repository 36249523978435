

import React, { useState } from 'react';
import { Tag } from 'antd';
import moment from "moment";
import { IoCopy, IoCopyOutline, IoEyeOutline } from "react-icons/io5";
import { formatNumberOnly } from '../../../utils/formatNumber';
import { Table } from '../../common/form_inputs/Table';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import { decryptData } from '../../../utils/cryptData';



const MandateStatusTable = ({rows, handleOpen}) => {

  const [copy, setCopy] = useState({
    icon: IoCopyOutline,
    state: false,
    id: null,
    text: "Copy url",
  });


  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const handleCopy = (val, index) => {
    copyToClipboard(`${baseUrl}/user/e_mandate/${val?.order_id}`)?.then((res) => {
      if(res.status) setCopy({ icon: IoCopy, state: true, text: "Copied", id: val?.id });
    })
};

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, row) => (
        <div className="flex flex-col">
          <p className="font-bold my-auto">{`${decryptData(row?.first_name)} ${decryptData(row?.last_name)}`}</p>
          <small className="text-gray-400" style={{ fontSize: '12px' }}>
            {moment(decryptData(row?.createdAt)).format('h:mm:ss a, D MMMM YYYY')}
          </small>
        </div>
      ),
    },
    {
      title: 'Mandate Ref',
      dataIndex: 'reference',
      render: (text, row)=>(
        <>
          {decryptData(row?.order_id)}
        </>
      )
    },
    {
      title: 'Total Repayable amount',
      dataIndex: 'totalRepayment',
      render: (text, row)=>(
        <>
          NGN {formatNumberOnly(parseFloat(decryptData(row?.total_amount)) - parseFloat(decryptData(row?.total_paid)))}
        </>
      )
    },
    {
      title: 'Total loan Repaid',
      dataIndex: 'totalRepayment',
      render: (text, row)=>(
        <>
          NGN {formatNumberOnly(parseFloat(decryptData(row?.total_paid)))}
          <div>
            <Tag color={decryptData(row?.status)==='active'?"success": decryptData(row?.status)==="reschedule approved"? "success": decryptData(row?.status)==="reschedule pending"? "purple": 'orange'} className='capitalize border-0'>{decryptData(row?.status)}</Tag>

          </div>
        </>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text, row)=>(
        <>
          <small className='cursor-pointer' onClick={()=>handleOpen(row)}>
            <IoEyeOutline size={"18px"}/>
  
          </small>
        </>
      )
    },
  ];



  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}));


  




  return (
    <>
        <Table
        onRow={(row)=>(
          {
            onClick:()=>handleOpen(row)
          }
        )}
        columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} pagination={false}
        striped={true}
        />
    
    </>
  );
};
export default MandateStatusTable;