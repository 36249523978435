export const ROUTES = {
    home: "/",
    staff_login: "/login",
    forgot_password: "/forgot_password",
    dashboard: "/dashboard",
    mandate_status: "/mandate_status",
    customers: "/customers",
    users: "/user",
    collection: "/collection",
    transaction: "/transactions",
    settings: "/settings?tab=profile",
    wallet_and_subscription: "/wallet_and_subscription",
    due_loans: "/due_loans",
    loan_application: "/loan_application?tab=loan_request"
}