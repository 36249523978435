
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../common/form_inputs/Input";
import CustomButton from "../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../hooks/zustand_hooks/useCustomerLoanApplication";

const BvnIdentity=({makeRequest, isPending})=>{

      const {
        data: { userDetail },
      } = useCustomerLoanApplication();


    const {
        handleSubmit,
        formState: { errors },
        trigger,
        getValues,
        control
      } = useForm({
        defaultValues: {
          bvn: userDetail?.bioData?.bvn,
        },
        mode: "onBlur",
        criteriaMode: "all",
      });


      const onSubmit = (data) => {
        makeRequest({
            bioData: {
                bvn: data?.bvn
            }
        });
      };

    return(
        <>
            <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Update your BVN</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center gap-x-5">
              <div className="mb-2 w-full">
                <Controller
                  name="bvn"
                  control={control}
                  rules={{
                    required: "This field is required",
                    pattern: {
                      value: /^\d{11}$/,
                      message: "BVN Number must be 11 digits long",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      name="bvn"
                      defaultValue={getValues("bvn")}
                      type="number"
                      placeholder="Enter your BVN"
                      size={"large"}
                      className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                        errors.bvn ? "error" : ""
                      }`}
                      status={errors.account_number ? "error" : ""}
                      onChange={(value) => {
                        field.onChange(value);
                        trigger("bvn");
                      }}
                    />
                   
                  )}
                />
                <small style={{ fontSize: "13px" }} className="text-red-500">
                  {errors.bvn && errors.bvn.message}
                </small>
              </div>
            </div>
            

            <div className="mt-7 mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                loading={isPending}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
        </>
    )
}


export default BvnIdentity;