import { Button } from "antd";
import React, { useState } from "react";
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import Confirm from "../../../../../common/Confirm";
import useCustomMessage from "../../../../../../hooks/zustand_hooks/useCustomMessage";
import { useUpdateKyc } from "../../../../../../api/admin_mutation";
import ViewBankStatement from "./ViewBankStatement";
import ViewBVN from "./ViewBVN";
import ViewMeterNumber from "./ViewMeterNumber";
import ViewCreditHistory from "./ViewCreditHistory";

const VerifyData = () => {
  const [dataIndex, setDataIndex] = useState(null);
  const [requestPayload, setRequestPayload] = useState(null);

  const { showMessage, contextHolder } = useCustomMessage();

  const {
    openDrawer,
    data: { userLoanID, keyName, account_status, responseData },
  } = useDrawer();



  const [openConfirm, setOpenConfirm] = useState(false);
  const { mutate: mutateUpdateKyc, isPending: updateKycLoading } =
    useUpdateKyc(userLoanID);

  const handleConfirm = () => {
    if (requestPayload) {
      mutateUpdateKyc(requestPayload, {
        onError: (error) => {
          const errMsg = error?.response?.data?.message || error?.message;

          showMessage("error", errMsg);
        },
        onSuccess: (data) => {
          const response = data?.data;

          showMessage(
            response?.status === "failed" ? "error" : "success",
            response?.message
          );
      openDrawer("KYC_PROCESS", {}, "USER_LOAN_SUMMARY")

        },
        onSettled: () => {
          setOpenConfirm(false);
        },
      });
    }
  };

  const handleUpdateKyc = (keyName, status) => {
    setDataIndex(keyName);
    const payload = {
      key: keyName,
      status: status,
    };

    setOpenConfirm(true);
    setRequestPayload(payload);
  };


  const components = {
    bankStatement: <ViewBankStatement detailData={responseData}/>,
    bvnIdentity: <ViewBVN detailData={responseData}/>,
    contact: <ViewMeterNumber detailData={responseData} />,
    credit_history: <ViewCreditHistory detailData={responseData} />,
  }



  return (
    <>
      <main>

        {
          keyName?.map((name, index)=>{
            return(
              <div key={index+"____"}>
                  {
                    components?.[name]
                }
                  <div className="flex justify-center gap-x-8 mt-5">
                    <Button
                      type="solid"
                      className="bg-[#733534] text-white hover:bg-[#733534] hover:text-white disabled:bg-[#8E6565]"
                      onClick={() =>
                        account_status?.[name] === "invalid"
                          ? null
                          : handleUpdateKyc(name, "invalid")
                      }
                      disabled={account_status?.[name] === "invalid"}
                    >
                      {account_status?.[name] === "invalid" ? "Rejected" : "Reject"}
                    </Button>
                    <Button
                      type="solid"
                      className="bg-green-500 text-white hover:bg-green-500 hover:text-white disabled:bg-[#6E8769]"
                      onClick={() =>
                        account_status?.[name] === "valid"
                          ? null
                          : handleUpdateKyc(name, "valid")
                      }
                      disabled={account_status?.[name] === "valid"}
                    >
                      {account_status?.[name] === "valid" ? "Verified" : "Verify"}
                    </Button>
                  </div>
              </div>
            )
          })
        }
      </main>

      <Confirm
        openConfirm={openConfirm}
        confirmLoading={updateKycLoading}
        setOpenConfirm={setOpenConfirm}
        message={"Are you sure to confirm this action?"}
        handleConfirm={handleConfirm}
      />

      {contextHolder}
    </>
  );
};

export default VerifyData;
