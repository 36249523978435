import React from "react";




const ViewBVN = ({detailData}) => {

  const detail = [
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
    { label: "Middle Name", value: "middle_name" },
    { label: "Email", value: "email" },
    { label: "Phone Number", value: "phone_number" },
    { label: "Gender", value: "gender" },
    { label: "Marital Status", value: "marital_status" },
    { label: "Date of Birth", value: "dob" },
  ];

  return (
    <>
      <main className="w-full max-w-lg mx-auto flex items-center justify-center mt-4">
        <div className="flex flex-col gap-y-10 w-full">
          <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm flex flex-col gap-y-8">
            <h4 className="font-[600] text-[18px] mb-2">BVN Detail</h4>
            <div className="flex flex-col gap-y-3">
              {(detail
              )?.map((item, index) => (
                <div key={item?.value + index} className="flex justify-between">
                  <h3 className="font-[400] text-[14px]">{item?.label}:</h3>
                  <p className="font-[275] text-[14px]">
                    {detailData?.[item?.value] || "N/A"}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewBVN;