
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import { useForm } from "react-hook-form";
import { useUserPermission } from "../../../../../../hooks/useUserPermission";
import { Input } from "../../../../../common/form_inputs/Input";
import CustomButton from "../../../../../common/CustomButton";
import InitiateMandateDrawer from "../../../../../common/drawer/InitiateMandateDrawer";

const CustomerInformation = ({ handleClick }) => {

  const {
    openDrawer,
    data: { values },
  } = useDrawer();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      nin: values?.nin,
      first_name: values?.first_name,
      last_name: values?.last_name,
      phone: values?.phone,
      email: values?.email,
    },
  });

  const { user_permission } = useUserPermission();

  const onSubmit = (data) => {
    console.log(data);
    openDrawer("CREDIT_SETTING", { values: data }, "CUSTOMER_INFORMATION");
    // reset();
  };

  const onChangeValue = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };
  return (
    <>
      <div className="flex items-center justify-center mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg border border-[#D9D9D996] bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Customer Information</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label htmlFor="nin" className="font-[400] text-[14px]">
                NIN
              </label>
              <Input
                name="nin"
                defaultValue={getValues("nin")}
                placeholder="Enter customer NIN"
                size={"large"}
                className={`text-[14px] ${errors.nin ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.nin ? "error" : ""}
                {...register("nin", {
                  required: "NIN is required",
                })}
                onChange={(e) => onChangeValue(e, "nin")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.nin && errors.nin.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="first_name" className="font-[400] text-[14px]">
                Customer’s First Name
              </label>
              <Input
                name="first_name"
                defaultValue={getValues("first_name")}
                placeholder="Enter Borrower’s Last Name"
                size={"large"}
                className={`text-[14px] ${errors.first_name ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.first_name ? "error" : ""}
                {...register("first_name", {
                  required: "First name is required",
                })}
                onChange={(e) => onChangeValue(e, "first_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.first_name && errors.first_name.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="last_name" className="font-[400] text-[14px]">
                Customer’s Last Name
              </label>
              <Input
                name="last_name"
                defaultValue={getValues("last_name")}
                placeholder="Enter Borrower’s Last Name"
                size={"large"}
                className={`text-[14px] ${errors.last_name ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.last_name ? "error" : ""}
                {...register("last_name", {
                  required: "Last name is required",
                })}
                onChange={(e) => onChangeValue(e, "last_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.last_name && errors.last_name.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="font-[400] text-[14px]">
                Customer’s Phone
              </label>
              <Input
                name="phone"
                defaultValue={getValues("phone")}
                type="number"
                placeholder="Enter Customer Phone number"
                size={"large"}
                className={`text-[14px] ${errors.phone ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.phone ? "error" : ""}
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^0\d{10}$/,
                    message:
                      "Phone number must start with 0 and be 11 digits long",
                  },
                })}
                onChange={(e) => onChangeValue(e, "phone")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.phone && errors.phone.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="font-[400] text-[14px]">
                Customer’s E-mail
              </label>
              <Input
                name="email"
                defaultValue={getValues("email")}
                type="email"
                placeholder="Enter Customer's Email"
                size={"large"}
                className={`text-[14px] ${errors.email ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.email ? "error" : ""}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                onChange={(e) => onChangeValue(e, "email")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.email && errors.email.message}
              </small>
            </div>

            <div className="mt-6 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                disabled={!user_permission?.create_mandate}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>

      <InitiateMandateDrawer handleClick={handleClick} />
    </>
  );
};

export default CustomerInformation;
