import React from "react";
import { Image } from "antd";
import avatar from "../../../../assets/avatar.png";
import CustomButton from "../../../common/CustomButton";
import RenderSection from "../../admin_side_loan/loan/loanApplication/kyc_process/RenderSection";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import { useLoanKyc } from "../../../../api/mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";

const personal_information_fields = [
  { value: "name", label: "Name" },
  { value: "phone", label: "Phone" },
  { value: "email", label: "Email" },
  { value: "gender", label: "Gender" },
  { value: "marital_status", label: "Marital Status" },
  { value: "dob", label: "Date of Birth" },
  { value: "nin", label: "NIN" },
];
const address_information_fields = [
  { value: "home_address", label: "Home Address" },
  { value: "city", label: "City" },
  { value: "state", label: "State" },
];
const work_fields = [
  { value: "occupation", label: "Occupation" },
  { value: "work_email", label: "Work Email" },
  { value: "salary", label: "Salary" },
  { value: "employer", label: "Employer" },
  { value: "employer_address", label: "Employer's Address" },
];

const KycProcess = ({paramID}) => {
  const {
    data: { form_values, userDetail },
    updateCurrentPage,
  } = useCustomerLoanApplication();

  const { mutate, isPending } = useLoanKyc(paramID)

  const { showMessage, contextHolder } = useCustomMessage()



  const handleContinue = () => {

    const payload = {
      bioData: {
        ...userDetail?.bioData,
      },
      contact: {
        ...userDetail?.contact,
    },
    employmentDetails: {
      ...userDetail?.employmentDetails,
    },
    profile_picture: userDetail?.profile_picture
    }


    
      mutate(payload, {
        onError: (error)=>{
          const errMsg = error?.response?.data?.message || error?.message;
  
          showMessage("error", errMsg);
        },
        onSuccess: (res)=>{
          if(userDetail?.loanSetting?.account_statment){
            updateCurrentPage("SHARE_BANK_STATEMENT", {});
          }else{
            updateCurrentPage("APPLICATION_COMPLETED", {
              response: {
                title: "KYC Process Completed",
                desc: "Your application will be reviewed",
                continue: true,
              },
            });
          }
        }
      })








    
  };

  console.log(userDetail?.profile_picture)

  return (
    <>
      <main className="w-full px-4">
        <h4 className="font-[600] text-[18px] mb-5 text-center">Summary</h4>

        <div className="flex justify-center mb-8">
          <Image
            preview={false}
            className="rounded-lg border border-[#908A8A]"
            width={200}
            src={userDetail?.profile_picture || avatar}
          />
        </div>

        <div className="flex flex-col gap-y-4">
          <RenderSection
            title={"Personal Information"}
            field_names={personal_information_fields}
            data={{
              ...form_values,
              ...userDetail?.bioData,
              marital_status: userDetail?.bioData?.maritalStatus,
              email: userDetail?.email,
              phone: userDetail?.phone,
              nin: userDetail?.nin,
              name: userDetail?.first_name + " " + userDetail?.last_name,
            }}
          />
          <RenderSection
            title={"Address Information"}
            field_names={address_information_fields}
            data={
              {
                ...form_values,
                ...userDetail?.contact,
                home_address: userDetail?.contact?.address,
              }
            }
          />
          <RenderSection
            title={"Work Information"}
            field_names={work_fields}
            data={{
              ...form_values,
              ...userDetail?.employmentDetails,
              work_email: userDetail?.employmentDetails?.workEmail,
              employer_address: userDetail?.employmentDetails?.employeraddress,
            }}
          />
        </div>

        <div className="flex justify-center gap-x-8 my-10">
          <CustomButton className="w-full md:w-3/5" onClick={handleContinue}
          loading={isPending}
          >
            Continue
          </CustomButton>
        </div>
      </main>


      {contextHolder}
    </>
  );
};

export default KycProcess;
