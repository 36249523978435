import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import { Controller, useForm } from "react-hook-form";
import { useUserPermission } from "../../../../../../hooks/useUserPermission";
import CustomButton from "../../../../../common/CustomButton";
import { Select } from "../../../../../common/form_inputs/Select";
import { states } from "../../../../../../libs/states";
import { Checkbox } from "antd";

const ageRanges = [
  {label: "18-24", value: "18-24"},
  {label: "25-34", value: "25-34"},
  {label: "35-45", value: "35-45"},
  {label: "46-59", value: "46-59"},
  {label: "Above 60", value: "Above-60"},
]

const DemographicAndLocation = () => {
  const {
    openDrawer,
    data: { values, edit_loan_setting },
  } = useDrawer();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
    watch,
    control,
  } = useForm({
    defaultValues: {
      age_range: values?.age_range || ageRanges?.map((item) => item?.value),
      states: values?.states,
    },
    mode: "onBlur", // Validate on blur or change
    criteriaMode: "all",
  });

  const onSubmit = (data) => {
    if (edit_loan_setting) {
      //will send the updated values to the server here and get the updates when it's successfull to return to loan setting view page
      openDrawer("LOAN_SETTING_VIEW", { loan_setting: { ...data } });
    } else {
      openDrawer(
        "APPLICATION_SUMMARY",
        { values: { ...values, ...data } },
        "CREDIT_REQUIREMENT_FORM"
      );
    }
  };




  const checkedRange = watch("age_range");

  const checkAll = ageRanges?.length === checkedRange?.length;
  const handleChangeAgeRange = (list) => {
    setValue("age_range", list);
  };
  const onCheckAllChange = (e) => {
    const allValues = ageRanges?.map((item) => item?.value);
    setValue("age_range", e.target.checked ? allValues : []);
  };

  return (
    <>
      <div className="flex items-center justify-center mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Demographic and Location</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label htmlFor="age_range" className="font-[400] text-[14px]">
                Select Age Range
              </label>
              <Controller
                name="age_range"
                control={control}
                rules={{
                  required: watch("not_applicable")
                    ? false
                    : "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <>
                    <Checkbox.Group
                      options={ageRanges}
                      value={checkedRange}
                      onChange={handleChangeAgeRange}
                    />
                    <Checkbox
                      // indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    >
                      All
                    </Checkbox>
                  </>
                )}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="states" className="font-[400] text-[14px]">
                Applicable States
              </label>
              <Controller
                name="states"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <Select
                  mode="multiple"
                    defaultValue={getValues("states")}
                    placeholder="Select states"
                    className="w-full"
                    showSearch
                    optionFilterProp="label"
                    status={errors.states ? "error" : ""}
                    onChange={(value) => {
                      setValue("states", value);
                      trigger("states");
                    }}
                    options={states}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.states && errors.states.message}
              </small>
            </div>

            <div className="mt-6 flex justify-center">
              <CustomButton className={"w-full"} type="submit">
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DemographicAndLocation;
