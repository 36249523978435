import { useState, useMemo } from "react";
import PersonalInformation from "./kyc_process/PersonalInformation";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import { useUpdateLoan } from "../../../../api/mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import WorkInformation from "./kyc_process/WorkInformation";
import AddressInformation from "./kyc_process/AddressInformation";
import NinIdentity from "../../rejectedDetailForm/NinIdentity";
import BvnIdentity from "../../rejectedDetailForm/BvnIdentity";
import ShareBankStatement from "./ShareBankStatement";
import CustomButton from "../../../common/CustomButton";
import {Result} from "antd"

const UpdateRejectedDetail = ({ paramID }) => {
  const { showMessage, contextHolder } = useCustomMessage();

  const {
    data: { userDetail },
  } = useCustomerLoanApplication();

  //   console.log(userDetail);

  const { mutate, isPending } = useUpdateLoan(userDetail?.id);

  const [selectedTab, setSelectTab] = useState(null);

  const changeTab = (value) => {
    setSelectTab(value);
  };

  
  const data = [
    { id: 1, label: "Personal Information", value: "bioData" },
    { id: 2, label: "Address Information", value: "contact" },
    { id: 3, label: "Work Information", value: "employmentDetails" },
    { id: 4, label: "BVN Identity", value: "bvnIdentity" },
    { id: 5, label: "NIN Identity", value: "ninIdentity" },
    { id: 6, label: "Update Bank Statement", value: "bankStatement" },
  ];

  const handleUpdateDetail = (payload) => {

    mutate({...payload, key: data[selectedTab-1]?.value}, {
      onError: (error) => {
        const errMsg = error?.response?.data?.message || error?.message;
        showMessage("error", errMsg);
      },
      onSuccess: (data) => {
        showMessage("success", data?.data?.message);
      },
    });
  };

  const components = {
    1: (
      <PersonalInformation
      makeRequest={handleUpdateDetail}
      isPending={isPending}
        updateForm={true}
      />
    ),
    2: (
      <AddressInformation
        makeRequest={handleUpdateDetail}
        isPending={isPending}
      />
    ),
    3: (
      <WorkInformation makeRequest={handleUpdateDetail} isPending={isPending} />
    ),
    4: <BvnIdentity makeRequest={handleUpdateDetail} isPending={isPending} />,
    5: <NinIdentity makeRequest={handleUpdateDetail} isPending={isPending} />,
    6: (
      <ShareBankStatement
        makeRequest={handleUpdateDetail}
        updateStatement={true}
        updateLoading={isPending}
        paramID={paramID}
      />
    ),
  };



  const fieldUpdated = useMemo(()=>{
    if(userDetail){
      return Object?.keys(userDetail?.account_status).find((item)=>userDetail?.account_status[item]==="invalid")
    }
}, [userDetail])


  return (
    <>
    {
      userDetail?.status==="rejected"? (
        <Result
          status={"error"}
          title={
            <>
              <p className="font-[500] text-[24px] text-[#2A2A29]">
                Loan Application Rejected
              </p>
              <p className="text-[0.95rem]">
                {userDetail?.account_status?.comment}
              </p>
              <br />
            </>
          }
          extra={null}
        />
      ):
        fieldUpdated ? (
            <main
        className={`grid md:grid-cols-3 grid-cols-1 gap-x-5 gap-y-5 w-full max-w-6xl mx-auto px-4`}
      >
        <div className="flex md:flex-col gap-3 flex-wrap col-span-1">
          {data?.map(
            (item, index) =>
              userDetail?.account_status?.[item?.value] === "invalid" && (
                <div
                  key={item?.id + "____" + index}
                  className={`rounded-full border px-3 py-1 cursor-pointer ${
                    selectedTab === item?.id ? "bg-[#733534] text-white" : ""
                  }`}
                  onClick={() => changeTab(item?.id)}
                >
                  {item?.label}
                </div>
              )
          )}

          {
            <div className="mt-10">
            <CustomButton
            className="w-full"
            disabled={fieldUpdated}
            >
                Finish
            </CustomButton>
            </div>
          }

        </div>
        <div className="md:col-span-2 mx-3">
          {selectedTab ? (
            components[selectedTab]
          ) : (
            <div className="flex justify-center items-center h-32">
              {userDetail?.account_status?.comment}
              <h3 className="font-[500] text-[16px] text-center text-[#323C4D]">

                Select a tab to update
              </h3>
            </div>
          )}
        </div>
      </main>
        ) : (
            <Result
          status={"success"}
          title={
            <>
              <p className="font-[500] text-[24px] text-[#2A2A29]">
                Information Updated Successfully
              </p>
              <p className="text-[0.95rem]">Your details will be reviewed</p>
              <br />
            </>
          }
          extra={null}
        />
        )
    }

      {contextHolder}
    </>
  );
};

export default UpdateRejectedDetail;
