import React, { forwardRef } from "react";
import { Input as AntInput, ConfigProvider } from "antd";

const { TextArea: AntTextArea } = AntInput;

export const TextArea = forwardRef(({ className, ...rest }, ref) => {
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              hoverBorderColor: "#733534",
              activeBorderColor: "#733534",
              activeShadow: "0 0 0 0px #733534",
            },
          },
        }}
      >
        <AntTextArea
          ref={ref}
          {...rest}
          className={`h-[42px] text-[14px] ${className}`}
        />
      </ConfigProvider>
    </>
  );
});
