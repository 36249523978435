import React from "react";
import avatar from "../../../../../assets/avatar.png";
import { Image } from "antd";
import { useForm } from "react-hook-form";
import { Input } from "../../../../common/form_inputs/Input";
import CustomButton from "../../../../common/CustomButton";
import { Select } from "../../../../common/form_inputs/Select";
import { useCustomerLoanApplication } from "../../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const WorkInformation = ({makeRequest, isPending}) => {
  const { data: { form_values, userDetail }, updateData } = useCustomerLoanApplication();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      ...userDetail?.employmentDetails
    }
  });

  const onSubmit = async(data) => {

    const payload = {
      employmentDetails: {
        "workEmail": data?.workEmail,
        "occupation": data?.occupation,
        "employer": data?.employer,
        "employeraddress": data?.employeraddress,
        "salary": data?.salary,
      }
    };

    await makeRequest(payload);

    updateData({ form_values: { ...form_values, ...data }} );
  };

  const onChangeValue = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName);
  };

  return (
    <>
      <main className="flex flex-col gap-y-5 my-5">
        <h3 className="font-[500] text-[16px] text-center">Work Information</h3>
        <div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Occupation
              </label>
              <Input
                name="occupation"
                defaultValue={getValues("occupation")}
                placeholder="e.g. Accountant"
                size={"large"}
                className={`text-[14px] ${errors.occupation ? "error" : ""}`}
                status={errors.occupation ? "error" : ""}
                {...register("occupation", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "occupation")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.occupation && errors.occupation.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="workEmail" className="font-[400] text-[14px]">
                Work Email
              </label>
              <Input
                name="workEmail"
                defaultValue={getValues("workEmail")}
                placeholder="e.g. Johndoe@work.com"
                size={"large"}
                className={`text-[14px] ${errors.workEmail ? "error" : ""}`}
                status={errors.workEmail ? "error" : ""}
                {...register("workEmail", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "workEmail")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.workEmail && errors.workEmail.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="salary" className="font-[400] text-[14px]">
                Monthly Salary
              </label>
              <Input
                name="salary"
                defaultValue={getValues("salary")}
                placeholder="e.g 40,000"
                size={"large"}
                className={`text-[14px] ${errors.salary ? "error" : ""}`}
                status={errors.salary ? "error" : ""}
                {...register("salary", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "salary")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.salary && errors.salary.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="employer" className="font-[400] text-[14px]">
                Employer
              </label>
              <Input
                name="employer"
                defaultValue={getValues("employer")}
                placeholder="Enter Place of work"
                size={"large"}
                className={`text-[14px] ${errors.employer ? "error" : ""}`}
                status={errors.employer ? "error" : ""}
                {...register("employer", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "employer")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.employer && errors.employer.message}
              </small>
            </div>
            <div className="mb-2">
              <label
                htmlFor="employeraddress"
                className="font-[400] text-[14px]"
              >
                Employer's Address
              </label>
              <Input
                name="employeraddress"
                defaultValue={getValues("employeraddress")}
                placeholder="e.g. 2, ola street"
                size={"large"}
                className={`text-[14px] ${
                  errors.employeraddress ? "error" : ""
                }`}
                status={errors.employeraddress ? "error" : ""}
                {...register("employeraddress", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "employeraddress")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.employeraddress && errors.employeraddress.message}
              </small>
            </div>

            <div className="mt-7 mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                loading={isPending}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default WorkInformation;
