import React from "react";
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import CustomButton from "../../../../../common/CustomButton";

const summaryData = [
  { value: "full_name", label: "Customer Full Name" },
  { value: "phone", label: "Phone Number" },
  { value: "email", label: "Email" },
  { value: "interest", label: "Interest" },
  { value: "request_guarantor", label: "Request Guarantor" },
  { value: "debit_guarantor", label: "Debit Guarantor in case of default" },
];

const loanSummaryData = [
  { value: "full_name", label: "Customer Full Name" },
  { value: "loan_amount", label: "Loan Amount" },
  { value: "interest", label: "Interest" },
  { value: "tenor", label: "Tenor" },
  { value: "repayment_amount", label: "Total Repayment Amount" },
  { value: "loan_type", label: "Loan Type" },
];

const CreditSummary = () => {
  const {
    openDrawer,
    pageTitle,
    updateDrawerTitle,
    data: { values, user_data },
  } = useDrawer();

  const handleOnComplete = () => {
    if (pageTitle === "USER_LOAN_SUMMARY") {
      openDrawer("KYC_PROCESS", {});
      updateDrawerTitle("KYC Process");
    } else {
      openDrawer("CREATE_SUCCESSFULL", {
        values: { ...values },
        response_message: "Loan application link sent to Johndoe@mail.com",
      });
    }
  };

  console.log(values, user_data)

  const detailData = pageTitle === "USER_LOAN_SUMMARY"
  ? user_data
  : values


  return (
    <>
      <main className="w-full max-w-lg mx-auto flex items-center justify-center mt-4">
        <div className="flex flex-col gap-y-10 w-full">
          <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm flex flex-col gap-y-8">
            <h4 className="font-[600] text-[18px] mb-2">Summary</h4>
            <div className="flex flex-col gap-y-3">
              {(pageTitle === "USER_LOAN_SUMMARY"
                ? loanSummaryData
                : summaryData
              )?.map((item, index) => (
                <div key={item?.value + index} className="flex justify-between">
                  <h3 className="font-[400] text-[14px]">{item?.label}:</h3>
                  <p className="font-[275] text-[14px]">
                    {typeof detailData?.[item?.value] === "boolean"
                      ? detailData?.[item?.value]
                        ? "Yes"
                        : "No"
                      : item?.value === "full_name"
                      ? `${detailData?.["first_name"]} ${detailData?.["last_name"]}`
                      : detailData?.[item?.value]}
                    {item?.value === "interest" && "%"}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <CustomButton className={"w-full"} onClick={handleOnComplete}>
              {
                pageTitle==="USER_LOAN_SUMMARY"? 
                "Next": "Complete"
              }
            </CustomButton>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreditSummary;
