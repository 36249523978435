import React from 'react'
import ViewPlans from "../../default_admin/view_plans/ViewPlans";
import { useProfileData } from "../../../../hooks/useProfileData";
const PlansView = () => {

    const { isAdmin } = useProfileData()

  return (
    <>
        <ViewPlans isAdmin={isAdmin}/>
    </>
  )
}

export default PlansView