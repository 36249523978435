

import { useState } from 'react';
import { Table } from "../../form_inputs/Table";
import moment from "moment";
import { formatNumberOnly } from '../../../../utils/formatNumber';
import { decryptData } from '../../../../utils/cryptData';
import { copyToClipboard } from '../../../../utils/copyToClipboard';
import { IoCopy, IoCopyOutline, IoEyeOutline } from "react-icons/io5";



const LoanSettingTable = ({rows, handleOpen}) => {

  const [copy, setCopy] = useState({
    icon: IoCopyOutline,
    state: false,
    id: null,
    text: "Copy url",
  });


  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const handleCopy = (val, index) => {
    copyToClipboard(`${baseUrl}/user/loan_application/${val?.loan_setting_id}`)?.then((res) => {
      if(res.status) setCopy({ icon: IoCopy, state: true, text: "Copied", id: val?.id });
    })
};


  const columns = [
    {
      title: 'Label',
      dataIndex: 'name',
      render: (text, row) => (
          <p className="flex flex-col">{(decryptData(row?.name) )?? ""}
           <small className="text-gray-400" style={{ fontSize: '12px' }}>
            {moment(decryptData(row?.createdAt)).format('h:mm:ss a, D MMMM YYYY')}
          </small>
          </p>
      ),
    },
    {
        title: 'Link',
        dataIndex: 'link',
        render: (text, row)=>(
          <span
          className="flex gap-x-1 text-[#733534] cursor-pointer"
          onClick={()=>handleCopy(row)}
        >
          {
            copy?.id===row?.id && copy?.state ? (
              <IoCopy color="#733534" size={"3vh"} />
            ): <IoCopyOutline color="#733534" size={"3vh"} />
          }
          {copy?.id===row?.id && copy?.state ? "Copied" : "Copy url"}
        </span>
        )
      },
    {
      title: 'Count',
      dataIndex: 'loanRequestsCount',
      render: (text, row) => (
          <p className="">{decryptData(row?.loanRequestsCount) ?? 0}</p>
    
      ),
    },
    
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, row)=>(
        <>
          <small className='cursor-pointer' 
          onClick={()=>handleOpen(row)}
          >
            <IoEyeOutline size={"18px"}/>
          </small>
        </>
      )
    },
  ];


  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}));





  return (
    <>
        <Table 
        // onRow={(row)=>(
        //   {
        //     onClick:()=>handleOpen(row)
        //   }
        // )} 
        columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} pagination={false} striped={true}/>
    
    </>
  );
};
export default LoanSettingTable;